export * from "ce/sagas/ApplicationSagas";
import {
  publishApplicationSaga,
  updateApplicationLayoutSaga,
  updateApplicationSaga,
  changeAppViewAccessSaga,
  changeAppSsoLoginSaga,
  changeAppSsoUseCustomPageSaga,
  changeAppSsoLoginTypesSaga,
  changeAppSsoApprovedDomainsSaga,
  changeAppSsoApprovedUsersSaga,
  changeAppSsoUnauthedPageIdsSaga,
  changeAppSsoLandingPageIdSaga,
  fetchAppAndPagesSaga,
  forkApplicationSaga,
  createApplicationSaga,
  setDefaultApplicationPageSaga,
  deleteApplicationSaga,
  importApplicationSaga,
  fetchReleases,
  initDatasourceConnectionDuringImport,
  showReconnectDatasourcesModalSaga,
  fetchUnconfiguredDatasourceList,
  uploadNavigationLogoSaga,
  deleteNavigationLogoSaga,
  fetchAllApplicationsOfWorkspaceSaga,
  changeAppZuoraNavSaga,
  changeAppAutoInstallSaga,
  changeAppChildrenEditableSaga,
  changeAppZTemplateSaga,
  changeAppZStoreNameSaga,
  changeAppZuoraNavOptionSaga,
  changeAppZConstantsSaga,
  changeAppZCustomVarsSaga,
  changeAppZInstanceConstantsSaga,
} from "ce/sagas/ApplicationSagas";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { all, takeLatest } from "redux-saga/effects";

export default function* applicationSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.PUBLISH_APPLICATION_INIT,
      publishApplicationSaga,
    ),
    takeLatest(ReduxActionTypes.UPDATE_APP_LAYOUT, updateApplicationLayoutSaga),
    takeLatest(ReduxActionTypes.UPDATE_APPLICATION, updateApplicationSaga),
    takeLatest(
      ReduxActionTypes.CHANGE_APPVIEW_ACCESS_INIT,
      changeAppViewAccessSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_SSO_LOGIN_INIT,
      changeAppSsoLoginSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_SSO_CUSTOM_PAGE_INIT,
      changeAppSsoUseCustomPageSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_SSO_LOGIN_TYPES_INIT,
      changeAppSsoLoginTypesSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_SSO_APPROVED_DOMAINS_INIT,
      changeAppSsoApprovedDomainsSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_SSO_APPROVED_USERS_INIT,
      changeAppSsoApprovedUsersSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_SSO_UNAUTHED_PAGES_INIT,
      changeAppSsoUnauthedPageIdsSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_SSO_LANDING_PAGE_INIT,
      changeAppSsoLandingPageIdSaga,
    ),
    takeLatest(
      ReduxActionTypes.FETCH_ALL_APPLICATIONS_OF_WORKSPACE_INIT,
      fetchAllApplicationsOfWorkspaceSaga,
    ),
    takeLatest(ReduxActionTypes.CHANGE_APP_ZUO_NAV_INIT, changeAppZuoraNavSaga),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_ZUO_NAV_OPTION_INIT,
      changeAppZuoraNavOptionSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_AUTOINSTALL_INIT,
      changeAppAutoInstallSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_CHILDREN_EDITABLE_INIT,
      changeAppChildrenEditableSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_ZTEMPLATE_INIT,
      changeAppZTemplateSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_ZSTORE_NAME_INIT,
      changeAppZStoreNameSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_ZCONSTANTS_INIT,
      changeAppZConstantsSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_ZINSTANCECONSTANTS_INIT,
      changeAppZInstanceConstantsSaga,
    ),
    takeLatest(
      ReduxActionTypes.CHANGE_APP_ZCUSTOMVARS_INIT,
      changeAppZCustomVarsSaga,
    ),
    takeLatest(ReduxActionTypes.FETCH_APPLICATION_INIT, fetchAppAndPagesSaga),
    takeLatest(ReduxActionTypes.FORK_APPLICATION_INIT, forkApplicationSaga),
    takeLatest(ReduxActionTypes.CREATE_APPLICATION_INIT, createApplicationSaga),
    takeLatest(
      ReduxActionTypes.SET_DEFAULT_APPLICATION_PAGE_INIT,
      setDefaultApplicationPageSaga,
    ),
    takeLatest(ReduxActionTypes.DELETE_APPLICATION_INIT, deleteApplicationSaga),
    takeLatest(ReduxActionTypes.IMPORT_APPLICATION_INIT, importApplicationSaga),
    takeLatest(
      ReduxActionTypes.UPLOAD_NAVIGATION_LOGO_INIT,
      uploadNavigationLogoSaga,
    ),
    takeLatest(
      ReduxActionTypes.DELETE_NAVIGATION_LOGO_INIT,
      deleteNavigationLogoSaga,
    ),
    takeLatest(ReduxActionTypes.FETCH_RELEASES, fetchReleases),
    takeLatest(
      ReduxActionTypes.INIT_DATASOURCE_CONNECTION_DURING_IMPORT_REQUEST,
      initDatasourceConnectionDuringImport,
    ),
    takeLatest(
      ReduxActionTypes.SHOW_RECONNECT_DATASOURCE_MODAL,
      showReconnectDatasourcesModalSaga,
    ),
    takeLatest(
      ReduxActionTypes.FETCH_UNCONFIGURED_DATASOURCE_LIST,
      fetchUnconfiguredDatasourceList,
    ),
  ]);
}
