import React from "react";
import { setActionProperty } from "actions/pluginActionActions";
import { API_EDITOR_TABS } from "PluginActionEditor/constants/ZuoraApiConstants";
import styled from "styled-components";
import { Switch, Text } from "@appsmith/ads";
import {
  CodeEditorBorder,
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/editorComponents/CodeEditor/EditorConfig";
import LazyCodeEditor from "components/editorComponents/LazyCodeEditor";
import { useDispatch } from "react-redux";

interface PayloadTransformProps {
  actionId: string;
  enabled: boolean;
  isChangePermitted: boolean;
  type: string;
  value: string;
}

const PayloadTransformToggleWrapper = styled.div`
  width: 100%;
  padding-top: 12px;
  padding-bottom: 1px;
  margin-bottom: ${(props) => props.theme.spaces[10] + 1}px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 24px;
  max-width: 600px;
`;

const ToggleLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
`;

const StyledFormInfo = styled.span`
  display: "inline-block";
  font-weight: normal;
  color: var(--ads-v2-color-fg-muted);
  font-size: 12px;
  margin-left: "0px";
  margin-top: 5px;
  margin-bottom: "0px";
  line-height: 16px;
`;

const SwitchContainer = styled.div`
  .ads-v2-switch__label {
    justify-content: flex-end;
    min-width: 0px;
  }
`;

const PayloadTransformEditorWrapper = styled.div`
  padding: 0px 0px 12px;
  background-color: var(--ads-v2-color-bg);
  height: 100%;
  .CodeMirror {
    height: auto;
    min-height: 250px;
  }
`;

function PayloadTransform({
  actionId,
  enabled,
  isChangePermitted,
  type,
  value,
}: PayloadTransformProps) {
  const dispatch = useDispatch();

  const updateProperty = (
    actionId: string,
    propertyName: string,
    value: string | boolean,
  ) => {
    dispatch(setActionProperty({ actionId, propertyName, value }));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PayloadTransformToggleWrapper>
        <HeaderWrapper>
          <Text kind="heading-xs">
            This JavaScript will be run on the server-side and can be used to
            securely transform data that should not be directly exposed to the
            client-side.
          </Text>
        </HeaderWrapper>
        <ToggleLabelContainer>
          <div>
            <p>Enabled</p>
            <StyledFormInfo>
              {type === API_EDITOR_TABS.PREEXECUTE
                ? "Will execute the following function on the request body before the request is sent"
                : "Will execute the following function on the response body after the response has been received"}
            </StyledFormInfo>
          </div>
          <SwitchContainer>
            <Switch
              className={"unchecked"}
              isSelected={enabled}
              onChange={(val: string | boolean) =>
                updateProperty(
                  actionId,
                  `actionConfiguration.${
                    type === API_EDITOR_TABS.PREEXECUTE
                      ? "preExecute"
                      : "postExecute"
                  }`,
                  val,
                )
              }
            />
          </SwitchContainer>
        </ToggleLabelContainer>
      </PayloadTransformToggleWrapper>
      <PayloadTransformEditorWrapper>
        {/* <JSONEditorFieldWrapper> */}
        <LazyCodeEditor
          AIAssisted
          border={CodeEditorBorder.ALL_SIDE}
          className={"js-editor"}
          disabled={!isChangePermitted && !enabled}
          folding
          hideEvaluatedValue
          input={{
            value: value,
            onChange: (val) => {
              updateProperty(
                actionId,
                `actionConfiguration.${
                  type === API_EDITOR_TABS.PREEXECUTE
                    ? "preExecuteTransform"
                    : "postExecuteTransform"
                }`,
                val,
              );
            },
          }}
          mode={EditorModes.JAVASCRIPT}
          placeholder={`function ${
            type === API_EDITOR_TABS.PREEXECUTE
              ? "preExecuteTransform"
              : "postExecuteTransform"
          }(data) {\n\treturn data;\n}`}
          showLightningMenu={false}
          showLineNumbers
          size={EditorSize.EXTENDED}
          tabBehaviour={TabBehaviour.INDENT}
          theme={EditorTheme.LIGHT}
        />
        {/* </JSONEditorFieldWrapper> */}
      </PayloadTransformEditorWrapper>
    </div>
  );
}

export default PayloadTransform;
