import API from "api/Api";
import type { AxiosPromise } from "axios";
import type { ApiResponse } from "../../api/ApiResponses";

class ZCachedAPI extends API {
  static url = "/v1/clear_cached";

  static async clearCached(
    actionId: string,
  ): Promise<AxiosPromise<ApiResponse>> {
    return API.post(ZCachedAPI.url + "/" + actionId);
  }
}

export default ZCachedAPI;
