export const getAssetUrl = (src = "") => {
  if (src.includes("/api/v1/zuora/assets")) {
    // Prefix with current host origin
    return `${window.location.origin}${src}`;
  }
  return src;
};

export const isAirgapped = () => {
  return false;
};
