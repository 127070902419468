/* eslint-disable @typescript-eslint/no-restricted-imports */
import { isInDevEnvironment } from "utils/isInDevEnvironment";
import { getTrustedOrigin } from "./getTrustedOrigin";
import { ZUORA_LOCALHOST_ORIGIN } from "ce/constants/zuora/PostMessageConstants";

export const targetDevOrigin = ZUORA_LOCALHOST_ORIGIN;

export const isValidOrigin = (origin: string): boolean => {
  const targetOrigin = isInDevEnvironment
    ? targetDevOrigin
    : getTrustedOrigin(origin);

  return !!(origin && origin === targetOrigin);
};
