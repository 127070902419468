import React from "react";
import { Switch, Divider, Text } from "@appsmith/ads";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentApplication,
  getIsChangingAutoInstall,
  getIsChangingZTemplate,
  getIsChangingChildrenEditable,
} from "ee/selectors/applicationSelectors";
import { getParentDefaultBranchSelector } from "selectors/gitSyncSelectors";
import PropertyHelpLabel from "pages/Editor/PropertyPane/PropertyHelpLabel";
import styled from "styled-components";
import {
  createMessage,
  IN_APP_EMBED_SETTING,
  MAKE_AUTOINSTALL,
  MAKE_AUTOINSTALL_TOOLTIP,
  MAKE_ZTEMPLATE,
  MAKE_ZTEMPLATE_TOOLTIP,
  MAKE_EDITABLE,
  MAKE_EDITABLE_TOOLTIP,
} from "ee/constants/messages";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import { getCurrentZSystemVars } from "selectors/editorSelectors";
import { getIsFetchingApplications } from "ee/selectors/selectedWorkspaceSelectors";
import {
  changeAppAutoInstall,
  changeAppZTemplate,
  changeIsChildrenEditable,
} from "ee/actions/zuoraActions";

const StyledPropertyHelpLabel = styled(PropertyHelpLabel)`
  .bp3-popover-content > div {
    text-align: center;
    max-height: 44px;
    display: flex;
    align-items: center;
  }
`;

const StyledSwitchLabel = styled(Text)`
  display: flex;
  flex-grow: 1;
  gap: var(--ads-v2-spaces-3);
  align-items: center;
  justify-content: space-between;
  min-width: 9rem;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: var(--ads-v2-font-size-4);
  line-height: 1.2rem;
  font-weight: var(--ads-v2-font-weight-bold);
  color: var(--ads-v2-color-fg-emphasis);
`;

export function ZuoraInternalSettings() {
  const application = useSelector(getCurrentApplication);
  const dispatch = useDispatch();
  const isFetchingApplication = useSelector(getIsFetchingApplications);
  const isChangingZTemplate = useSelector(getIsChangingZTemplate);
  const isChangingAutoInstall = useSelector(getIsChangingAutoInstall);
  const isChangingChildrenEditable = useSelector(getIsChangingChildrenEditable);
  const isForkedFromAutoInstall = useSelector(getParentDefaultBranchSelector);
  const isForkedZApp = isForkedFromAutoInstall;

  const autoInstallReleaseBranch =
    useSelector(getCurrentZSystemVars)["ZENV_RELEASE_BRANCH"]?.toString() || "";

  return (
    <div>
      <div className="px-4 mt-4 mb-3">
        <Title>
          {createMessage(IN_APP_EMBED_SETTING.templateContentHeader)}
        </Title>
      </div>
      <div className="px-4">
        <div className="flex justify-between content-center">
          <Switch
            data-testid="t--embed-settings-application-public"
            isDisabled={
              isFetchingApplication ||
              isChangingZTemplate ||
              isChangingAutoInstall ||
              isChangingChildrenEditable
            }
            isSelected={application?.isZTemplate}
            onChange={() => {
              AnalyticsUtil.logEvent("MAKE_ZTEMPLATE", {
                isZTemplate: !application?.isZTemplate,
              });
              application &&
                dispatch(
                  changeAppZTemplate(
                    application?.gitApplicationMetadata?.defaultApplicationId ||
                      application?.id,
                    !application?.isZTemplate,
                  ),
                );
            }}
          >
            <StyledPropertyHelpLabel
              label={createMessage(MAKE_ZTEMPLATE)}
              lineHeight="1.17"
              maxWidth="270px"
              tooltip={createMessage(MAKE_ZTEMPLATE_TOOLTIP)}
            />
          </Switch>
        </div>
      </div>
      <Divider />
      {!isForkedZApp && (
        <>
          <div className="px-4 mt-4 mb-3">
            <Title>
              {createMessage(IN_APP_EMBED_SETTING.autoInstallContentHeader)}
            </Title>
          </div>
          <div className="px-4">
            <div className="flex justify-between content-center">
              <Switch
                data-testid="t--embed-settings-application-public"
                isDisabled={
                  isFetchingApplication ||
                  isChangingZTemplate ||
                  isChangingAutoInstall ||
                  isChangingChildrenEditable
                }
                isSelected={application?.isAutoInstall}
                onChange={() => {
                  AnalyticsUtil.logEvent("MAKE_AUTOINSTALL", {
                    isAutoInstall: !application?.isAutoInstall,
                  });
                  application &&
                    dispatch(
                      changeAppAutoInstall(
                        application?.gitApplicationMetadata
                          ?.defaultApplicationId || application?.id,
                        !application?.isAutoInstall,
                      ),
                    );
                }}
              >
                <StyledPropertyHelpLabel
                  label={createMessage(MAKE_AUTOINSTALL)}
                  lineHeight="1.17"
                  maxWidth="270px"
                  tooltip={createMessage(MAKE_AUTOINSTALL_TOOLTIP)}
                />
              </Switch>
            </div>
          </div>
          <Divider />{" "}
        </>
      )}
      {application?.clonedFromApplicationId === undefined && (
        <>
          <div className="px-4 mt-4 mb-3">
            <Title>
              {createMessage(
                IN_APP_EMBED_SETTING.childrenEditableContentHeader,
              )}
            </Title>
          </div>
          <div className="px-4">
            <div className="flex justify-between content-center">
              <Switch
                data-testid="t--embed-settings-application-children-editable"
                isDisabled={
                  isFetchingApplication ||
                  isChangingZTemplate ||
                  isChangingAutoInstall ||
                  isChangingChildrenEditable
                }
                isSelected={application?.isChildrenEditable}
                onChange={() => {
                  AnalyticsUtil.logEvent("MAKE_CHILD_EDITABLE", {
                    isChildrenEditable: !application?.isChildrenEditable,
                  });
                  application &&
                    dispatch(
                      changeIsChildrenEditable(
                        application?.gitApplicationMetadata
                          ?.defaultApplicationId || application?.id,
                        !application?.isChildrenEditable,
                      ),
                    );
                }}
              >
                <StyledPropertyHelpLabel
                  label={createMessage(MAKE_EDITABLE)}
                  lineHeight="1.17"
                  maxWidth="270px"
                  tooltip={createMessage(MAKE_EDITABLE_TOOLTIP)}
                />
              </Switch>
            </div>
          </div>
          <Divider />
        </>
      )}
      {autoInstallReleaseBranch && (
        <>
          <div className="px-4 mt-4 mb-3">
            <Title>{createMessage(IN_APP_EMBED_SETTING.releaseBranch)}</Title>
          </div>
          <div className="px-4">
            <div className="flex justify-between content-center">
              <StyledSwitchLabel>
                <StyledPropertyHelpLabel
                  label={"v11.1"}
                  lineHeight="1.17"
                  maxWidth="270px"
                />
              </StyledSwitchLabel>
            </div>
          </div>
          <Divider />
        </>
      )}
    </div>
  );
}
