import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ReactJson from "react-json-view"; // Import the react-json-view library
import { Button } from "@appsmith/ads"; // Import the Button component from the design system
import {
  getCurrentApplicationId,
  getCurrentApplicationZconstants,
} from "selectors/editorSelectors";
import type { ZBaseConstantsState } from "reducers/entityReducers/zConstantsReducer";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { setZConstants } from "ce/actions/zuoraActions";

const SettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: 100%;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #e8e8e8;
  background-color: #ffffff;
`;

export function GlobalConfigSettings() {
  const zConstants = useSelector(getCurrentApplicationZconstants);
  const applicationId = useSelector(getCurrentApplicationId);
  const [jsonContent, setJsonContent] = React.useState(zConstants);
  const dispatch = useDispatch();

  function handleSave() {
    dispatch(setZConstants(applicationId, JSON.stringify(jsonContent)));
  }

  function handleEdit(edit: {
    updated_src: React.SetStateAction<ZBaseConstantsState>;
  }) {
    setJsonContent(edit.updated_src);
  }

  function handleAdd(add: {
    updated_src: React.SetStateAction<ZBaseConstantsState>;
  }) {
    setJsonContent(add.updated_src);
  }

  function handleDelete(del: {
    updated_src: React.SetStateAction<ZBaseConstantsState>;
  }) {
    setJsonContent(del.updated_src);
  }

  return (
    <SettingWrapper>
      <ContentWrapper>
        <ReactJson
          collapsed={false}
          displayDataTypes={false}
          enableClipboard
          name={false}
          onAdd={handleAdd}
          onDelete={handleDelete}
          onEdit={handleEdit}
          src={jsonContent}
        />
      </ContentWrapper>
      <SaveButtonWrapper>
        <Button kind="primary" onClick={handleSave}>
          Save Configuration
        </Button>
      </SaveButtonWrapper>
    </SettingWrapper>
  );
}
