import React, { useState } from "react";
import { Condition } from "./Condition";
import { setToValue } from "./Utils";
import _ from "lodash";

export const ConditionTab = (props) => {
  const { fieldSelectionObjectPath, querySelection, setQuerySelection } = props;

  const [conditionJson, setCondition] = useState(
    _.get(querySelection, fieldSelectionObjectPath + ".filter", {}),
  );

  function updateQuerySelection(conditionCP) {
    let temp = _.cloneDeep(querySelection);
    setToValue(temp, conditionCP, fieldSelectionObjectPath + ".filter");
    setQuerySelection(temp);
    setCondition(conditionCP);
  }

  function findCurCondition(id, conditionCP) {
    const splitedIds = id.split("-");
    const root = conditionCP.conditions;
    let cur = root;
    // omit root i start 1
    for (let i = 1; i < splitedIds.length; i++) {
      cur = cur.conditions[splitedIds[i]];
    }
    return cur;
  }

  function findCurParentCondition(id, conditionCP) {
    const splitedIds = id.split("-");
    const root = conditionCP.conditions;
    let cur = root;
    // omit root i start 1 end with -1 element
    for (let i = 1; i < splitedIds.length - 1; i++) {
      cur = cur.conditions[splitedIds[i]];
    }
    return cur;
  }

  function findGrandCondition(id, conditionCP) {
    const splitedIds = id.split("-");
    const root = conditionCP.conditions;
    let cur = root;
    // omit root i start 1 end with -2 element
    for (let i = 1; i < splitedIds.length - 2; i++) {
      cur = cur.conditions[splitedIds[i]];
    }
    return cur;
  }

  // update condition state func
  function updateCondition(id, operator, fieldName, value, conditionObj) {
    // Condition is empty
    if (
      _.isEmpty(_.get(conditionJson, "conditions.conditions", [])) &&
      operator === "add"
    ) {
      setCondition({
        conditions: {
          model: "complex",
          relation: "AND",
          conditions: [
            {
              operator: "EQ",
              field: "",
              value: "",
              type: "static",
            },
          ],
        },
      });
      return;
    }

    let conditionCP = _.cloneDeep(conditionJson);
    if (operator === "add") {
      let cur = findCurCondition(id, conditionCP);
      if (cur.model === "complex") {
        // complex
        cur.conditions.push({
          model: "simple",
          operator: "EQ",
          field: "",
          value: "",
          type: "static",
        });
        updateQuerySelection(conditionCP);
      } else {
        // simple
        const curSimpleCondition = { ...cur };
        cur.model = "complex";
        cur.relation = "AND";
        delete cur.field;
        delete cur.value;
        cur.conditions = [
          curSimpleCondition,
          {
            model: "simple",
            operator: "EQ",
            field: "",
            value: "",
            type: "static",
          },
        ];
        updateQuerySelection(conditionCP);
      }
    } else if (operator === "delete") {
      const splitedIds = id.split("-");
      const curParent = findCurParentCondition(id, conditionCP);
      if (curParent.conditions.length > 1) {
        // more than one condition just splice the condition
        curParent.conditions.splice(splitedIds[splitedIds.length - 1], 1);
      } else {
        // find grand condition to remove it
        const grand = findGrandCondition(id, conditionCP);
        grand.conditions.splice(splitedIds[splitedIds.length - 2], 1);
      }
      updateQuerySelection(conditionCP);
    } else if (operator === "update") {
      const cur = findCurCondition(id, conditionCP);
      if (conditionObj) {
        Object.assign(cur, conditionObj);
      } else {
        cur[fieldName] = value;
      }
      // cur[fieldName] = value;
      updateQuerySelection(conditionCP);
    }
  }

  return (
    <div style={{ paddingTop: "16px" }}>
      <Condition
        data={_.get(conditionJson, "conditions.conditions", [])}
        index="root"
        objectFieldSelectProps={props.objectFieldSelectProps}
        relation={_.get(conditionJson, "conditions.relation", "")}
        updateCondition={updateCondition}
      />
    </div>
  );
};
