import React, { useCallback } from "react";
import styled from "styled-components";
import { Tabs, TabsList, Tab, TabPanel } from "@appsmith/ads";
import { useDispatch, useSelector } from "react-redux";
import { GlobalConfigSettings } from "./GlobalConfigSettings";
import { InstanceConfigSettings } from "./InstanceConfigSettings";
import type { AppState } from "ee/reducers";
import { getSelectedZConstantsTabIndex } from "selectors/appSettingsPaneSelectors";
import { setSelectedZConstantsTabIndex } from "actions/appSettingsPaneActions";
import { Text } from "@appsmith/ads"; // Import the Button component from the design system
import { ZCONSTANTS_SETTING, createMessage } from "ee/constants/messages";

const SettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: 100%;
`;

const tabs = ["base", "instance"];

const StyledTabs = styled(Tabs)`
  height: 100%;
  > [role="tabpanel"] {
    margin-top: 0;
    height: 95%;
  }

  > [role="tablist"] {
    top: 74px;
    z-index: 3;
    background: var(--ads-v2-color-white);
    overflow: hidden;
    padding: var(--ads-v2-spaces-1) var(--ads-v2-spaces-4);
  }
`;

export function ZConstantsTab() {
  const dispatch = useDispatch();
  const selectedIndex = useSelector((state: AppState) =>
    getSelectedZConstantsTabIndex(state),
  );

  const setSelectedIndex = useCallback(
    (index: number) => {
      dispatch(setSelectedZConstantsTabIndex(index));
    },
    [dispatch],
  );

  const onValueChange = useCallback(
    (value) => {
      setSelectedIndex(tabs.indexOf(value) || 0);
    },
    [setSelectedIndex],
  );

  return (
    <SettingWrapper>
      <Text kind="heading-s">
        {createMessage(ZCONSTANTS_SETTING.settingHeader)}
      </Text>
      <StyledTabs onValueChange={onValueChange} value={tabs[selectedIndex]}>
        <TabsList>
          <Tab value={tabs[0]}>Base</Tab>
          <Tab value={tabs[1]}>Instance</Tab>
        </TabsList>
        <TabPanel value={tabs[0]}>
          <GlobalConfigSettings />
        </TabPanel>
        <TabPanel value={tabs[1]}>
          <InstanceConfigSettings />
        </TabPanel>
      </StyledTabs>
    </SettingWrapper>
  );
}
