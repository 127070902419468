import * as React from "react";
import { Table, AutoThemeProvider, Icon, Checkbox, Typography } from "zds";
import _ from "lodash";

const adminOnlyFields = ["Email", "FirstName", "LastName", "CreatedDate"];

function FieldsSelection(props) {
  const {
    allCustomFields,
    containedCustomFields,
    fieldList,
    isAdminLimit = false,
    selectedRows,
    setCustomFieldOption,
    setQuerySelection,
  } = props;
  let unsupportFields = _.differenceBy(selectedRows, fieldList, "name");

  return (
    <AutoThemeProvider density={"condensed"}>
      {containedCustomFields && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Checkbox
            checked={allCustomFields}
            dsOnChange={(event, checked) => {
              setCustomFieldOption(checked);
            }}
            e2e="myCheckbox"
            label="Retrieve all custom fields (including any future additions)."
            name="allCustomFields"
          />
        </div>
      )}
      <Table
        columns={[
          {
            field: "name",
            label: "Field Name",
            dsRenderCell: ({ value }) => {
              if (
                !_.isEmpty(
                  unsupportFields.filter((item) => item.name === value),
                )
              ) {
                return (
                  <div style={{ color: "red" }}>
                    {value}{" "}
                    <Icon
                      body={"error"}
                      color={"error"}
                      fontSize={"small"}
                      tooltip={"Unsupported field. Please unselect."}
                    />
                  </div>
                );
              }
              return (
                <div>
                  {value}
                  {isAdminLimit && adminOnlyFields.includes(value) ? (
                    <span style={{ marginLeft: "8px" }}>
                      <Typography color={"red"} variant={"caption"}>
                        Admin Read Only, will cause errors for users that are
                        not admin
                      </Typography>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              );
            },
          },
        ]}
        e2e={"obj-query-fields-table"}
        hidePagination
        rows={[...unsupportFields, ...fieldList]}
        rowsPerPage={100}
        searchable
        uniqueKey={"name"}
        {...(!allCustomFields && {
          dsOnSelectionChange: (selectedRowData) => {
            setQuerySelection(selectedRowData);
          },
          selectedRows: Object.values(selectedRows).reduce(
            (acc, row) => ({
              ...acc,
              [row.name]: row,
            }),
            {},
          ),
          selectionStrategy: "global",
          showSelectAll: true,
        })}
      />
    </AutoThemeProvider>
  );
}

export default React.memo(FieldsSelection);
