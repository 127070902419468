import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import * as log from "loglevel";
import { put } from "redux-saga/effects";
import type { WFDefinition } from "entities/WFCollection";
import type { ApiResponse } from "api/ApiResponses";
import WFActionAPI from "ee/api/WFActionAPI";

// eventually pass in specific applicationId/pageId to only fetch
// workflows that will be configurable to appear for specific apps
export function* fetchWFCollectionsSaga() {
  try {
    const response: ApiResponse<WFDefinition[]> =
      yield WFActionAPI.fetchWFCollections();
    yield put({
      type: ReduxActionTypes.FETCH_WF_ACTIONS_SUCCESS,
      payload: response.data || [],
    });
  } catch (error) {
    log.error("Error fetching Workflow actions.", error);
    yield put({
      type: ReduxActionTypes.FETCH_WF_ACTIONS_SUCCESS,
      payload: [],
    });
  }
}
