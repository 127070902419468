import API from "api/Api";
import type { AxiosPromise } from "axios";
import { fetchZuoraEnvironment } from "../../utils/URLUtils";
import type { ApiResponse } from "../../api/ApiResponses";

class ZGraphQLAPI extends API {
  static currentEnv = fetchZuoraEnvironment(window.location.hostname);
  static isLocalhost = ZGraphQLAPI.currentEnv === "local";
  static baseURL = ZGraphQLAPI.isLocalhost
    ? process.env.ZENV_URL || "https://rest-staging2.zuora.com"
    : "/platform/gateway-proxy-v2";

  static url = "/object-query/graphql";
  static zGraphQLAPIConfig = {
    baseURL: ZGraphQLAPI.baseURL,
    headers: ZGraphQLAPI.isLocalhost
      ? {
          Authorization:
            "Basic dGF5bG9yLm1lZGZvcmRAenVvcmEuY29tOlRlc3RpbmcxMjM=",
          "Accept-Encoding": "gzip, deflate",
          "Access-Control-Allow-Origin": "*",
        }
      : { Scope: "Internal", "Accept-Encoding": "gzip, deflate" },
    timeout: 1000 * 60 * 2,
    withCredentials: false,
  };

  static async fetchGraphSchema(
    body: FormData | null,
  ): Promise<AxiosPromise<ApiResponse>> {
    return API.post(ZGraphQLAPI.url, body, null, ZGraphQLAPI.zGraphQLAPIConfig);
  }
}

export default ZGraphQLAPI;
