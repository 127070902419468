/* eslint-disable @typescript-eslint/no-restricted-imports */
import Api from "api/Api";
import type { AxiosPromise } from "axios";
import type { ZWorkflow } from "ce/types/zuora";
import ZuoraComponentsApi, {
  type ResponseEnvelope,
} from "./ZuoraComponentsApi";

export interface WorkflowComponentResponse
  extends ResponseEnvelope<ZWorkflow[]> {}

export class ZuoraWorkflowComponentsApi extends ZuoraComponentsApi {
  static componentType = "workflows";

  static async saveWorkflows(
    workflows: ZWorkflow[],
    extensionId: string | undefined,
  ): Promise<AxiosPromise<WorkflowComponentResponse>> {
    return ZuoraWorkflowComponentsApi.checker<WorkflowComponentResponse>(
      extensionId,
      ZuoraWorkflowComponentsApi.doSaveWorkflows,
      workflows,
    );
  }

  static async loadWorkflows(
    extensionId: string | undefined,
  ): Promise<AxiosPromise<WorkflowComponentResponse>> {
    return ZuoraWorkflowComponentsApi.checker<WorkflowComponentResponse>(
      extensionId,
      ZuoraWorkflowComponentsApi.doLoadWorkflows,
    );
  }

  private static async doSaveWorkflows(
    workflows: ZWorkflow[],
    extensionId: string,
  ): Promise<AxiosPromise<WorkflowComponentResponse>> {
    return Api.post(
      `${ZuoraWorkflowComponentsApi.baseURL}/${ZuoraWorkflowComponentsApi.componentType}/update-collection`,
      workflows,
      null,
      {
        headers: {
          ContentType: "application/json",
          ...ZuoraWorkflowComponentsApi.getSharedRequestHeaders(extensionId),
        },
      },
    );
  }

  private static async doLoadWorkflows(
    extensionId: string,
  ): Promise<AxiosPromise<WorkflowComponentResponse>> {
    return Api.get(
      `${ZuoraWorkflowComponentsApi.baseURL}/${ZuoraWorkflowComponentsApi.componentType}`,
      null,
      {
        headers:
          ZuoraWorkflowComponentsApi.getSharedRequestHeaders(extensionId),
      },
    );
  }
}

export default ZuoraWorkflowComponentsApi;
