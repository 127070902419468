import { promisify } from "./utils/Promisify";

function logoutFnDescriptor(pageName: string) {
  return {
    type: "LOGOUT" as const,
    payload: { pageName },
  };
}

export type TLogoutArgs = Parameters<typeof logoutFnDescriptor>;
export type TLogoutDescription = ReturnType<typeof logoutFnDescriptor>;
export type TLogoutActionType = TLogoutDescription["type"];

async function logout(...args: Parameters<typeof logoutFnDescriptor>) {
  return promisify(logoutFnDescriptor)(...args);
}

export default logout;
