export const trustedOrigins = [
  "https://staging2.zuora.com",
  "https://apisandbox.zuora.com",
  "https://eu.zuora.com",
  "https://na.zuora.com",
  "https://sandbox.eu.zuora.com",
  "https://sandbox.na.zuora.com",
  "https://apisandbox.zuora.com",
  "https://test.zuora.com",
  "https://app-0.qad.eu.zuora.com",
  "https://app-1.qad.eu.zuora.com",
  "https://one.stg.na.zuora.com",
  "https://one.zuora.com",
  "http://app-5.qad.na.zuora.com",
  "https://www.zuora.com",
];

export const getTrustedOrigin = (origin: string) =>
  trustedOrigins.find((trustedOrigin: string) => origin === trustedOrigin);
