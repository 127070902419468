/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-restricted-imports */
import {
  saveCustomEvents,
  saveCustomFields,
  saveObjectComponents,
  saveWorkflows,
} from "ce/actions/zuoraActions";
import type {
  ZComponentManagerPostMessageData,
  PersistentZObjectDefinition,
  ZCustomEvent,
  ZCustomFieldDefinition,
  ZWorkflow,
} from "ce/types/zuora";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

export const useSaveComponents = ({
  extensionId: _extensionId,
}: {
  extensionId: string;
}) => {
  const extensionIdRef = useRef<string>("");

  useEffect(() => {
    if (_extensionId) {
      extensionIdRef.current = _extensionId;
    }
  }, [_extensionId]);

  const getExtensionId = () => {
    return extensionIdRef.current;
  };

  const dispatch = useDispatch();

  const saveCustomField = (customFields: ZCustomFieldDefinition[]) => {
    dispatch(
      saveCustomFields({
        componentData: customFields,
        extensionId: getExtensionId(),
      }),
    );
  };

  const saveObject = (objects: PersistentZObjectDefinition[]) => {
    dispatch(
      saveObjectComponents({
        componentData: objects,
        extensionId: getExtensionId(),
      }),
    );
  };

  const saveCustomEvent = (customEvent: ZCustomEvent[]) => {
    dispatch(
      saveCustomEvents({
        componentData: customEvent,
        extensionId: getExtensionId(),
      }),
    );
  };

  const saveWorkflow = (workflows: ZWorkflow[]) => {
    dispatch(
      saveWorkflows({
        componentData: workflows,
        extensionId: getExtensionId(),
      }),
    );
  };

  const saveComponents = ({
    componentType,
    data,
  }: ZComponentManagerPostMessageData) => {
    switch (componentType) {
      case "customField":
        return saveCustomField(data as ZCustomFieldDefinition[]);

      case "customObject":
        return saveObject(data as PersistentZObjectDefinition[]);

      case "customEvent":
        return saveCustomEvent(data as ZCustomEvent[]);

      case "workflow":
        return saveWorkflow(data as ZWorkflow[]);

      default:
        console.error("Invalid component type");
    }
  };

  return { saveComponents };
};
