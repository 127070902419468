/* eslint-disable @typescript-eslint/no-restricted-imports */
import Api from "api/Api";
import type { AxiosPromise } from "axios";
import type { PersistentZObjectDefinition } from "ce/types/zuora";
import ZuoraComponentsApi, {
  type ResponseEnvelope,
} from "./ZuoraComponentsApi";

export interface BaseObjectComponentResponse
  extends ResponseEnvelope<PersistentZObjectDefinition[]> {}

export class ZuoraCustomObjectComponentsApi extends ZuoraComponentsApi {
  static componentType = "objects";

  static async saveExtensionObjects(
    objectData: PersistentZObjectDefinition[],
    extensionId: string | undefined,
  ): Promise<AxiosPromise<BaseObjectComponentResponse>> {
    return ZuoraCustomObjectComponentsApi.checker<BaseObjectComponentResponse>(
      extensionId,
      ZuoraCustomObjectComponentsApi.doSaveExtensionObject,
      objectData,
    );
  }

  static async loadExtensionObjects(
    extensionId?: string,
  ): Promise<AxiosPromise<BaseObjectComponentResponse>> {
    return ZuoraCustomObjectComponentsApi.checker<BaseObjectComponentResponse>(
      extensionId,
      ZuoraCustomObjectComponentsApi.doLoadExtensionObjects,
    );
  }

  private static async doSaveExtensionObject(
    objectData: PersistentZObjectDefinition[],
    extensionId: string,
  ): Promise<AxiosPromise<BaseObjectComponentResponse>> {
    return Api.post(
      `${ZuoraCustomObjectComponentsApi.baseURL}/${ZuoraCustomObjectComponentsApi.componentType}/update-collection`,
      objectData,
      null,
      {
        headers: {
          ContentType: "application/json",
          ...ZuoraCustomObjectComponentsApi.getSharedRequestHeaders(
            extensionId,
          ),
        },
      },
    );
  }

  private static async doLoadExtensionObjects(
    extensionId: string,
  ): Promise<AxiosPromise<BaseObjectComponentResponse>> {
    return Api.get(
      `${ZuoraCustomObjectComponentsApi.baseURL}/${ZuoraCustomObjectComponentsApi.componentType}`,
      null,
      {
        headers:
          ZuoraCustomObjectComponentsApi.getSharedRequestHeaders(extensionId),
      },
    );
  }
}

export default ZuoraCustomObjectComponentsApi;
