import { createReducer } from "utils/ReducerUtils";
import type { ReduxAction } from "ee/constants/ReduxActionConstants";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";
import type { Template, TemplateFiltersResponse } from "api/TemplatesApi";

const initialState: ZTemplatesReduxState = {
  isImportingTemplate: false,
  isImportingTemplateToApp: false,
  loadingFilters: false,
  gettingAllTemplates: false,
  gettingTemplate: false,
  activeTemplate: null,
  activeLoadingTemplateId: null,
  templates: [],
  similarTemplates: [],
  filters: {
    functions: ["All"],
  },
  allFilters: {
    functions: [],
  },
  templateSearchQuery: "",
  templateNotificationSeen: null,
  templatesModal: {
    isOpen: false,
    isOpenFromCanvas: false,
  },
};

const ztemplateReducer = createReducer(initialState, {
  [ReduxActionTypes.GET_ALL_ZTEMPLATES_INIT]: (state: ZTemplatesReduxState) => {
    return {
      ...state,
      gettingAllTemplates: true,
    };
  },
  [ReduxActionTypes.GET_ALL_ZTEMPLATES_SUCCESS]: (
    state: ZTemplatesReduxState,
    action: ReduxAction<Template[]>,
  ) => {
    return {
      ...state,
      gettingAllTemplates: false,
      templates: action.payload,
    };
  },
  [ReduxActionTypes.GET_ZTEMPLATE_INIT]: (state: ZTemplatesReduxState) => {
    return {
      ...state,
      gettingTemplate: true,
    };
  },
  [ReduxActionTypes.GET_ZTEMPLATE_SUCCESS]: (
    state: ZTemplatesReduxState,
    action: ReduxAction<Template>,
  ) => {
    return {
      ...state,
      gettingTemplate: false,
      activeTemplate: action.payload,
    };
  },
  [ReduxActionTypes.IMPORT_ZTEMPLATE_TO_WORKSPACE_INIT]: (
    state: ZTemplatesReduxState,
  ) => ({ ...state, isImportingTemplate: true }),
  [ReduxActionTypes.IMPORT_ZTEMPLATE_TO_WORKSPACE_SUCCESS]: (
    state: ZTemplatesReduxState,
  ) => ({ ...state, isImportingTemplate: false }),
  [ReduxActionErrorTypes.IMPORT_ZTEMPLATE_TO_WORKSPACE_ERROR]: (
    state: ZTemplatesReduxState,
  ) => ({ ...state, isImportingTemplate: false }),
});

export interface ZTemplatesReduxState {
  allFilters: TemplateFiltersResponse["data"] | Record<string, never>;
  gettingAllTemplates: boolean;
  gettingTemplate: boolean;
  templates: Template[];
  activeTemplate: Template | null;
  activeLoadingTemplateId: string | null;
  similarTemplates: Template[];
  filters: Record<string, string[]>;
  templateSearchQuery: string;
  isImportingTemplate: boolean;
  isImportingTemplateToApp: boolean;
  buildingBlockSourcePageId?: string;
  templateNotificationSeen: boolean | null;
  templatesModal: {
    isOpen: boolean;
    isOpenFromCanvas: boolean;
  };
  loadingFilters: boolean;
}

export default ztemplateReducer;
