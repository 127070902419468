interface ZObjectSchema {
  object: string;
  label: string;
  relationships: {
    cardinality: string;
    namespace: string;
    object: string;
    fields: {
      [key: string]: string;
    };
    recordConstraints: {
      create: {
        enforceValidMapping: boolean;
      };
    };
  }[];
  properties: ZObjectFieldDefinition;
  required: string[];
  filterable: string[];
  type: string;
}

interface ZObjectFieldDefinition {
  [key: string]: {
    maxLength: number;
    label: string;
    origin: string;
    type: string;
    format?: string;
  };
}

interface ZObjectDefinition {
  Id: string;
  type: string;
  CreatedById: string;
  UpdatedById: string;
  CreatedDate: string;
  UpdatedDate: string;
  schema: ZObjectSchema;
}
/*
  Defines both standard and custom zuora object defintions.
  
  The isCustomObject property is used to differentiate between the two.
 */
export interface PersistentZObjectDefinition extends ZObjectDefinition {
  isCustomObject: boolean;
}

export enum ZAvailableExtensions {
  CustomField = "customField",
  CustomEvent = "customEvent",
  CustomObject = "customObject",
  Workflow = "workflow",
}

export interface ZComponentManagerPostMessageData {
  componentType: ZAvailableExtensions;
  data: unknown[];
}

export interface ZCustomFieldDefinition {
  id: string;
  object: string;
  field: string;
  label: string;
  type: string;
  description: string;
  enum: string[];
  default: string;
  format: string;
}

export interface ZCustomEvent {
  id: string;
  baseObject: string;
  condition: string;
  description: string;
  eventType: {
    name: string;
    displayName: string;
    description: string;
    namespace: string;
  };
  active: boolean;
}

export interface ZWorkflow {
  workflow_definition: {
    id: number;
    name: string;
    description: string;
    category: string;
    ui_page_roles: string[];
  };
  workflow: {
    id: number;
    name: string;
    description: string;
    parameters: {
      fields: string[];
      skipping_check: string;
      file_encryption: string;
      show_run_prompt: string | null;
      callout_response: string;
      secure_error_msgs: string;
      disable_pending_status: boolean;
    };
    data: Record<string, unknown>;
    type: string;
    ondemand_trigger: boolean;
    callout_trigger: boolean;
    scheduled_trigger: boolean;
    interval: string | null;
    timezone: string | null;
    status: string;
    css: Record<string, string | number>;
    notifications: Record<string, unknown>;
    call_type: string;
    priority: string;
    delete_ttl: number;
    version: string;
    event_trigger: boolean;
    zuora_org_id: string | null;
    ui_trigger: boolean;
    ui_pages: Record<string, unknown>;
    solution_id: string | null;
    zuora_org_ids: string[];
    extension_id: string | null;
  };
  tasks: {
    id: number;
    name: string;
    parameters: Record<string, unknown>;
    action_type: string;
    object: string | null;
    object_id: string | null;
    call_type: string;
    task_id: number | null;
    css: Record<string, string | number>;
    concurrent_limit: number;
    tags: string[];
    priority: string;
    assignment: string[];
    zuora_org_id: string | null;
    zuora_org_ids: string[];
  }[];
  linkages: {
    source_workflow_id: number | null;
    source_task_id: number | null;
    target_task_id: number;
    linkage_type: string;
  }[];
}
