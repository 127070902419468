/* eslint-disable @typescript-eslint/no-restricted-imports */
import Api from "api/Api";
import type { AxiosPromise } from "axios";
import type { ZCustomEvent } from "ce/types/zuora";
import ZuoraComponentsApi, {
  type ResponseEnvelope,
} from "./ZuoraComponentsApi";

export interface CustomEventComponentResponse
  extends ResponseEnvelope<ZCustomEvent[]> {}

export class ZuoraCustomEventComponentsApi extends ZuoraComponentsApi {
  static componentType = "custom-events";

  static async saveCustomEvents(
    customEvents: ZCustomEvent[],
    extensionId: string | undefined,
  ): Promise<AxiosPromise<CustomEventComponentResponse>> {
    return ZuoraCustomEventComponentsApi.checker<CustomEventComponentResponse>(
      extensionId,
      ZuoraCustomEventComponentsApi.doSaveCustomEvents,
      customEvents,
    );
  }

  static async loadCustomEvents(
    extensionId: string | undefined,
  ): Promise<AxiosPromise<CustomEventComponentResponse>> {
    return ZuoraCustomEventComponentsApi.checker<CustomEventComponentResponse>(
      extensionId,
      ZuoraCustomEventComponentsApi.doLoadCustomEvents,
    );
  }

  private static async doSaveCustomEvents(
    customEvents: ZCustomEvent[],
    extensionId: string,
  ): Promise<AxiosPromise<CustomEventComponentResponse>> {
    return Api.post(
      `${ZuoraCustomEventComponentsApi.baseURL}/${ZuoraCustomEventComponentsApi.componentType}/update-collection`,
      customEvents,
      null,
      {
        headers: {
          ContentType: "application/json",
          ...ZuoraCustomEventComponentsApi.getSharedRequestHeaders(extensionId),
        },
      },
    );
  }

  private static async doLoadCustomEvents(
    extensionId: string,
  ): Promise<AxiosPromise<CustomEventComponentResponse>> {
    return Api.get(
      `${ZuoraCustomEventComponentsApi.baseURL}/${ZuoraCustomEventComponentsApi.componentType}`,
      null,
      {
        headers:
          ZuoraCustomEventComponentsApi.getSharedRequestHeaders(extensionId),
      },
    );
  }
}

export default ZuoraCustomEventComponentsApi;
