/* eslint-disable @typescript-eslint/no-restricted-imports */
import Api from "api/Api";
import type { AxiosPromise } from "axios";
import type { ZCustomFieldDefinition } from "ce/types/zuora";
import ZuoraComponentsApi, {
  type ResponseEnvelope,
} from "./ZuoraComponentsApi";

export interface CustomFieldComponentResponse
  extends ResponseEnvelope<ZCustomFieldDefinition[]> {}

export class ZuoraCustomFieldComponentsApi extends ZuoraComponentsApi {
  static componentType = "custom-fields";

  static async saveCustomFields(
    customFields: ZCustomFieldDefinition[],
    extensionId: string | undefined,
  ): Promise<AxiosPromise<CustomFieldComponentResponse>> {
    return ZuoraCustomFieldComponentsApi.checker<CustomFieldComponentResponse>(
      extensionId,
      ZuoraCustomFieldComponentsApi.doSaveCustomFields,
      customFields,
    );
  }

  static async loadCustomFields(
    extensionId: string | undefined,
  ): Promise<AxiosPromise<CustomFieldComponentResponse>> {
    return ZuoraCustomFieldComponentsApi.checker<CustomFieldComponentResponse>(
      extensionId,
      ZuoraCustomFieldComponentsApi.doLoadCustomFields,
    );
  }

  private static async doSaveCustomFields(
    customFields: CustomFieldComponentResponse,
    extensionId: string,
  ): Promise<AxiosPromise<CustomFieldComponentResponse>> {
    return Api.post(
      `${ZuoraCustomFieldComponentsApi.baseURL}/${ZuoraCustomFieldComponentsApi.componentType}/update-collection`, // TODO: update when endpoint is available
      customFields,
      null,
      {
        headers: {
          ContentType: "application/json",
          ...ZuoraCustomFieldComponentsApi.getSharedRequestHeaders(extensionId),
        },
      },
    );
  }

  private static async doLoadCustomFields(
    extensionId: string,
  ): Promise<AxiosPromise<CustomFieldComponentResponse>> {
    return Api.get(
      `${ZuoraCustomFieldComponentsApi.baseURL}/${ZuoraCustomFieldComponentsApi.componentType}`,
      null,
      {
        headers:
          ZuoraCustomFieldComponentsApi.getSharedRequestHeaders(extensionId),
      },
    );
  }
}

export default ZuoraCustomFieldComponentsApi;
