/* eslint-disable @typescript-eslint/no-restricted-imports */
import type { AppState } from "ce/reducers";
import type {
  ZAvailableExtensions,
  ZComponentManagerPostMessageData,
} from "ce/types/zuora";
import React, { type ReactNode, createContext } from "react";
import { useSelector } from "react-redux";
import { useLoadComponents } from "utils/hooks/zuoraComponents/useLoadComponents";
import { useSaveComponents } from "utils/hooks/zuoraComponents/useSaveComponents";

interface ZComponentPersistenceContext {
  saveComponents: ({
    componentType,
    data,
  }: ZComponentManagerPostMessageData) => void;
  loadComponents: (componentType: ZAvailableExtensions) => void;
}

const ZComponentPersistenceContext = createContext(
  {} as ZComponentPersistenceContext,
);

interface ZComponentPersistenceProviderProps {
  children: ReactNode;
}

const ZComponentPersistenceProvider: React.FC<
  ZComponentPersistenceProviderProps
> = ({ children }) => {
  const activeApplicationIdSelector: string | undefined = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.id,
  );

  const { saveComponents } = useSaveComponents({
    extensionId: activeApplicationIdSelector ?? "",
  });
  const { loadComponents } = useLoadComponents({
    extensionId: activeApplicationIdSelector ?? "",
  });

  return (
    <ZComponentPersistenceContext.Provider
      value={{
        saveComponents,
        loadComponents,
      }}
    >
      {children}
    </ZComponentPersistenceContext.Provider>
  );
};

export { ZComponentPersistenceContext, ZComponentPersistenceProvider };
