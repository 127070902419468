import { createMessage, EXPORT_APP } from "ee/constants/messages";
import { Text, Button } from "@appsmith/ads";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentApplicationId } from "selectors/editorSelectors";
import styled from "styled-components";
import { getExportAppAPIRoute } from "ee/constants/ApiConstants";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";
import { showZuoraToast } from "ZToast/ZToast.actions";

const SettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

export function ExportAppSettings() {
  const appId = useSelector(getCurrentApplicationId);
  const dispatch = useDispatch();

  const currentApplication = useSelector(getCurrentApplication);
  const exportAppAsJSON = () => {
    const id = `t--export-app-link`;
    const existingLink = document.getElementById(id);
    existingLink && existingLink.remove();
    const link = document.createElement("a");

    const branchName = currentApplication?.gitApplicationMetadata?.branchName;
    link.href = getExportAppAPIRoute(appId, branchName);
    link.id = id;
    document.body.appendChild(link);
    // @ts-expect-error: Types are not available
    if (!window.Cypress) {
      link.click();
    }
    dispatch(
      showZuoraToast(`Successfully exported ${currentApplication?.name}`, {
        kind: "success",
      }),
    );
  };
  return (
    <SettingWrapper>
      <Text kind="heading-s">{createMessage(EXPORT_APP.settingHeader)}</Text>
      <Text kind="body-m">{createMessage(EXPORT_APP.settingContent)}</Text>
      <Button
        UNSAFE_width="40%"
        data-testid="t--app-setting-import-btn"
        onClick={() => exportAppAsJSON()}
        size="sm"
      >
        {createMessage(EXPORT_APP.settingActionButtonTxt)}
      </Button>
    </SettingWrapper>
  );
}
