import { showToastOnExecutionError } from "./errorUtils";
import { call, spawn } from "redux-saga/effects";
declare let window: any;
export function* submitHPMSaga() {
  yield spawn(executeSubmitHPM);
}

export function* executeSubmitHPM() {
  try {
    window.Z.submit();
  } catch (error) {
    yield call(showToastOnExecutionError, (error as Error).message, true);
  }
}
