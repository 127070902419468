import React from "react";
import { Button, Callout } from "@appsmith/ads";
import { createMessage, LOGIN_SETTING } from "ee/constants/messages";
import { convertedLoginNames } from "ee/constants/SocialLogin";

function SsoPage() {
  const appId = localStorage.getItem("sso_app_id");
  const ssoLoginTypes =
    localStorage.getItem("sso_login_types")?.split(",") || [];
  const queryParams = new URLSearchParams(location.search);
  let showError = false;
  let errorMessage = "";
  if (queryParams.get("error")) {
    errorMessage = queryParams.get("message") || queryParams.get("error") || "";
    showError = true;
  }

  return (
    <div
      className={`absolute inset-0 flex flex-col items-center justify-center space-y-6 bg-[color:var(--ads-color-background-secondary)]`}
    >
      {showError && <Callout kind="error">{errorMessage}</Callout>}
      <p className="text-3xl font-semibold t--sso-page-title">
        {createMessage(LOGIN_SETTING.selectSso)}
      </p>
      {ssoLoginTypes.length == 0 && (
        <p className="text-center t--error-page-description">
          {createMessage(LOGIN_SETTING.noSsoLoginsEnabled)}
        </p>
      )}
      {ssoLoginTypes.map((type) => (
        <a
          href={"/api/v1/zuora/users/sso_login/" + type + "/" + appId}
          key={type}
        >
          <Button className="mt-4 button-position" kind="primary" size="md">
            {createMessage(LOGIN_SETTING.loginWith) +
              convertedLoginNames.get(type)}
          </Button>
        </a>
      ))}
    </div>
  );
}

export default SsoPage;
