/* eslint-disable @typescript-eslint/no-restricted-imports */
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type {
  PersistentZObjectDefinition,
  ZCustomEvent,
  ZCustomFieldDefinition,
  ZWorkflow,
} from "ce/types/zuora";
export const changeAppZuoraNav = (
  applicationId: string,
  showZuoraNav: boolean,
) => {
  return {
    type: ReduxActionTypes.CHANGE_APP_ZUO_NAV_INIT,
    payload: {
      applicationId,
      showZuoraNav,
    },
  };
};

export const changeAppZuoraNavOptions = (
  applicationId: string,
  navOption: string,
) => {
  return {
    type: ReduxActionTypes.CHANGE_APP_ZUO_NAV_OPTION_INIT,
    payload: {
      applicationId,
      navOption,
    },
  };
};

export const changeAppZTemplate = (
  applicationId: string,
  isZTemplate: boolean,
) => {
  return {
    type: ReduxActionTypes.CHANGE_APP_ZTEMPLATE_INIT,
    payload: {
      applicationId,
      isZTemplate,
    },
  };
};

export const changeAppZStoreName = (
  applicationId: string,
  zstorename?: string,
) => {
  return {
    type: ReduxActionTypes.CHANGE_APP_ZSTORE_NAME_INIT,
    payload: {
      applicationId,
      zstorename,
    },
  };
};

export const changeAppAutoInstall = (
  applicationId: string,
  isAutoInstall: boolean,
) => {
  return {
    type: ReduxActionTypes.CHANGE_APP_AUTOINSTALL_INIT,
    payload: {
      applicationId,
      isAutoInstall,
    },
  };
};

export const changeIsChildrenEditable = (
  applicationId: string,
  isChildrenEditable: boolean,
) => {
  return {
    type: ReduxActionTypes.CHANGE_APP_CHILDREN_EDITABLE_INIT,
    payload: {
      applicationId,
      isChildrenEditable,
    },
  };
};

export const setIsComponentManagerLoaded = (isLoaded: boolean) => {
  return {
    type: ReduxActionTypes.ZUORA_SET_COMPONENT_MANAGER_IS_LOADED,
    payload: isLoaded,
  };
};

export const setZConstants = (applicationId: string, zConstants?: string) => {
  return {
    type: ReduxActionTypes.CHANGE_APP_ZCONSTANTS_INIT,
    payload: {
      applicationId,
      zConstants,
    },
  };
};

export const setZInstanceConstants = (
  applicationId: string,
  zInstanceConstants?: string,
) => {
  return {
    type: ReduxActionTypes.CHANGE_APP_ZINSTANCECONSTANTS_INIT,
    payload: {
      applicationId,
      zInstanceConstants,
    },
  };
};

interface KeyValue {
  key: string;
  value: string;
}

export const setZCustomVars = (zCustomVars: KeyValue[]) => {
  return {
    type: ReduxActionTypes.CHANGE_APP_ZCUSTOMVARS_INIT,
    payload: {
      zCustomVars,
    },
  };
};

export interface ActionProps<T> {
  componentData: T;
  extensionId: string;
}

// OBJECT COMPONENTS

export const resetObjectComponentSaveStatus = () => {
  return {
    type: ReduxActionTypes.ZUORA_RESET_OBJECT_COMPONENT_SAVE_STATUS,
  };
};

export const resetObjectComponentLoadStatus = () => {
  return {
    type: ReduxActionTypes.ZUORA_RESET_OBJECT_COMPONENT_LOAD_STATUS,
  };
};

// SAVE OBJECT COMPONENTS

export const saveObjectComponents = (
  payload: ActionProps<PersistentZObjectDefinition[]>,
) => ({
  type: ReduxActionTypes.ZUORA_SAVE_OBJECT_COMPONENTS_INIT,
  payload,
});

export const saveObjectComponentsSuccess = (
  payload: PersistentZObjectDefinition[],
) => ({
  type: ReduxActionTypes.ZUORA_SAVE_OBJECT_COMPONENTS_SUCCESS,
  payload,
});

export const saveObjectComponentsFailure = () => ({
  type: ReduxActionTypes.ZUORA_SAVE_OBJECT_COMPONENTS_FAILURE,
});

// LOAD OBJECT COMPONENTS
export const loadObjectComponents = (extensionId: string) => ({
  type: ReduxActionTypes.ZUORA_LOAD_OBJECT_COMPONENTS_INIT,
  payload: { extensionId },
});

export const loadObjectComponentsSuccess = (
  payload: PersistentZObjectDefinition[],
) => ({
  type: ReduxActionTypes.ZUORA_LOAD_OBJECT_COMPONENTS_SUCCESS,
  payload,
});

export const loadObjectComponentsFailure = () => ({
  type: ReduxActionTypes.ZUORA_LOAD_OBJECT_COMPONENTS_FAILURE,
});

// CUSTOM FIELDS

export const resetCustomFieldSaveStatus = () => ({
  type: ReduxActionTypes.ZUORA_RESET_CUSTOM_FIELD_SAVE_STATUS,
});

export const resetCustomFieldLoadStatus = () => ({
  type: ReduxActionTypes.ZUORA_RESET_CUSTOM_FIELD_LOAD_STATUS,
});

// SAVE CUSTOM FIELDS

export const saveCustomFields = (
  payload: ActionProps<ZCustomFieldDefinition[]>,
) => ({
  type: ReduxActionTypes.ZUORA_SAVE_CUSTOM_FIELDS_INIT,
  payload,
});

export const saveCustomFieldsSuccess = (payload: ZCustomFieldDefinition[]) => ({
  type: ReduxActionTypes.ZUORA_SAVE_CUSTOM_FIELDS_SUCCESS,
  payload,
});

export const saveCustomFieldsFailure = () => ({
  type: ReduxActionTypes.ZUORA_SAVE_CUSTOM_FIELDS_FAILURE,
});

// LOAD CUSTOM FIELDS

export const loadCustomFields = (extensionId: string) => ({
  type: ReduxActionTypes.ZUORA_LOAD_CUSTOM_FIELDS_INIT,
  payload: { extensionId },
});

export const loadCustomFieldsSuccess = (payload: ZCustomFieldDefinition[]) => ({
  type: ReduxActionTypes.ZUORA_LOAD_CUSTOM_FIELDS_SUCCESS,
  payload,
});

export const loadCustomFieldsFailure = () => ({
  type: ReduxActionTypes.ZUORA_LOAD_CUSTOM_FIELDS_FAILURE,
});

// CUSTOM EVENTS

export const resetCustomEventSaveStatus = () => ({
  type: ReduxActionTypes.ZUORA_RESET_CUSTOM_EVENT_SAVE_STATUS,
});

export const resetCustomEventLoadStatus = () => ({
  type: ReduxActionTypes.ZUORA_RESET_CUSTOM_EVENT_LOAD_STATUS,
});

// SAVE CUSTOM EVENTS

export const saveCustomEvents = (payload: ActionProps<ZCustomEvent[]>) => ({
  type: ReduxActionTypes.ZUORA_SAVE_CUSTOM_EVENTS_INIT,
  payload,
});

export const saveCustomEventsSuccess = (payload: ZCustomEvent[]) => ({
  type: ReduxActionTypes.ZUORA_SAVE_CUSTOM_EVENTS_SUCCESS,
  payload,
});

export const saveCustomEventsFailure = () => ({
  type: ReduxActionTypes.ZUORA_SAVE_CUSTOM_EVENTS_FAILURE,
});

// LOAD CUSTOM EVENTS

export const loadCustomEvents = (extensionId: string) => ({
  type: ReduxActionTypes.ZUORA_LOAD_CUSTOM_EVENTS_INIT,
  payload: { extensionId },
});

export const loadCustomEventsSuccess = (payload: ZCustomEvent[]) => ({
  type: ReduxActionTypes.ZUORA_LOAD_CUSTOM_EVENTS_SUCCESS,
  payload,
});

export const loadCustomEventsFailure = () => ({
  type: ReduxActionTypes.ZUORA_LOAD_CUSTOM_EVENTS_FAILURE,
});

// WORKFLOWS

export const resetWorkflowSaveStatus = () => ({
  type: ReduxActionTypes.ZUORA_RESET_WORKFLOW_SAVE_STATUS,
});

export const resetWorkflowLoadStatus = () => ({
  type: ReduxActionTypes.ZUORA_RESET_WORKFLOW_LOAD_STATUS,
});

// SAVE WORKFLOWS

export const saveWorkflows = (payload: ActionProps<ZWorkflow[]>) => ({
  type: ReduxActionTypes.ZUORA_SAVE_WORKFLOWS_INIT,
  payload,
});

export const saveWorkflowsSuccess = (payload: ZWorkflow[]) => ({
  type: ReduxActionTypes.ZUORA_SAVE_WORKFLOWS_SUCCESS,
  payload,
});

export const saveWorkflowsFailure = () => ({
  type: ReduxActionTypes.ZUORA_SAVE_WORKFLOWS_FAILURE,
});

// LOAD WORKFLOWS

export const loadWorkflows = (extensionId: string) => ({
  type: ReduxActionTypes.ZUORA_LOAD_WORKFLOWS_INIT,
  payload: { extensionId },
});

export const loadWorkflowsSuccess = (payload: ZWorkflow[]) => ({
  type: ReduxActionTypes.ZUORA_LOAD_WORKFLOWS_SUCCESS,
  payload,
});

export const loadWorkflowsFailure = () => ({
  type: ReduxActionTypes.ZUORA_LOAD_WORKFLOWS_FAILURE,
});
