import type { EvaluationReduxAction } from "ee/constants/ReduxActionConstants";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";

export const updatePositionsOnStepChange = (
  widgetId: string,
  selectedStepWidgetId: string,
) => {
  return {
    type: ReduxActionTypes.UPDATE_POSITIONS_ON_STEP_CHANGE,
    payload: { selectedStepWidgetId, widgetId },
  };
};

export const importZTemplateToWorkspace = (
  templateId: string,
  workspaceId: string,
) => ({
  type: ReduxActionTypes.IMPORT_ZTEMPLATE_TO_WORKSPACE_INIT,
  payload: {
    templateId,
    workspaceId,
  },
});

export const fetchZConstantsInit = (pages: any) => ({
  type: ReduxActionTypes.FETCH_ZCONSTANTS_INIT,
  payload: {
    pages,
  },
});

export const fetchZEnvConstantsInit = (zenvconstants: any) => ({
  type: ReduxActionTypes.FETCH_ZENVCONSTANTS_INIT,
  payload: {
    zenvconstants,
  },
});

export const fetchZuoraGenesisInfoInit = (
  zuoraGenesisInfo: any,
  pages: any,
) => ({
  type: ReduxActionTypes.FETCH_ZUORA_GENESIS_INFO_INIT,
  payload: {
    zuoraGenesisInfo,
    pages,
  },
});

export const fetchZSessionInit = (sessionData: any) => ({
  type: ReduxActionTypes.SET_ZUORA_SESSION_DATA_INIT,
  payload: {
    sessionData,
  },
});

export const initActionPayloadTransformers = (payload: { id: string }) => ({
  type: ReduxActionTypes.CREATE_MISSING_TRANSFORMERS_INIT,
  payload: payload,
});

export const logoutZUser = (payload?: { redirectURL: string }) => ({
  type: ReduxActionTypes.ZLOGOUT_USER_INIT,
  payload,
});

export interface FetchWFCollectionsPayload {}

export const fetchWFCollections = (): EvaluationReduxAction<unknown> => {
  return {
    type: ReduxActionTypes.FETCH_WF_ACTIONS_INIT,
    payload: null,
  };
};
