/* eslint-disable @typescript-eslint/no-restricted-imports */
import ZuoraCustomEventComponentsApi, {
  type CustomEventComponentResponse,
} from "api/zuora/ZuoraCustomEventComponentsApi";
import ZuoraCustomFieldComponentsApi, {
  type CustomFieldComponentResponse,
} from "api/zuora/ZuoraCustomFieldComponentsApi";
import ZuoraCustomObjectComponentsApi, {
  type BaseObjectComponentResponse,
} from "api/zuora/ZuoraCustomObjectComponentsApi";
import ZuoraWorkflowComponentsApi, {
  type WorkflowComponentResponse,
} from "api/zuora/ZuoraWorkflowComponentsApi";
import {
  type ActionProps,
  loadCustomEventsFailure,
  loadCustomEventsSuccess,
  loadCustomFieldsFailure,
  loadCustomFieldsSuccess,
  loadObjectComponentsFailure,
  loadObjectComponentsSuccess,
  loadWorkflowsFailure,
  loadWorkflowsSuccess,
  saveCustomEventsFailure,
  saveCustomEventsSuccess,
  saveCustomFieldsFailure,
  saveCustomFieldsSuccess,
  saveObjectComponentsFailure,
  saveObjectComponentsSuccess,
  saveWorkflowsFailure,
  saveWorkflowsSuccess,
} from "ce/actions/zuoraActions";
import type { ReduxAction } from "ce/constants/ReduxActionConstants";
import type {
  PersistentZObjectDefinition,
  ZCustomEvent,
  ZCustomFieldDefinition,
  ZWorkflow,
} from "ce/types/zuora";

import { call, put } from "redux-saga/effects";

export function* saveObjectComponentsSaga(
  action: ReduxAction<ActionProps<PersistentZObjectDefinition[]>>,
) {
  try {
    const { componentData, extensionId } = action?.payload;
    const { data }: BaseObjectComponentResponse = yield call(
      ZuoraCustomObjectComponentsApi.saveExtensionObjects,
      componentData,
      extensionId,
    );
    yield put(saveObjectComponentsSuccess(data));
  } catch (error) {
    yield put(saveObjectComponentsFailure());
  }
}

export function* loadObjectComponentsSaga(
  action: ReduxAction<{ extensionId?: string }>,
) {
  try {
    const { data }: BaseObjectComponentResponse = yield call(
      ZuoraCustomObjectComponentsApi.loadExtensionObjects,
      action?.payload.extensionId,
    );
    yield put(loadObjectComponentsSuccess(data));
  } catch (error) {
    yield put(loadObjectComponentsFailure());
  }
}

export function* saveCustomFieldComponentsSaga(
  action: ReduxAction<ActionProps<ZCustomFieldDefinition[]>>,
) {
  try {
    const { componentData, extensionId } = action?.payload;
    const { data }: CustomFieldComponentResponse = yield call(
      ZuoraCustomFieldComponentsApi.saveCustomFields,
      componentData,
      extensionId,
    );
    yield put(saveCustomFieldsSuccess(data));
  } catch (error) {
    yield put(saveCustomFieldsFailure());
  }
}

export function* loadCustomFieldComponentsSaga(
  action: ReduxAction<{ extensionId?: string }>,
) {
  try {
    const { data }: CustomFieldComponentResponse = yield call(
      ZuoraCustomFieldComponentsApi.loadCustomFields,
      action?.payload.extensionId,
    );
    yield put(loadCustomFieldsSuccess(data));
  } catch (error) {
    yield put(loadCustomFieldsFailure());
  }
}

export function* saveCustomEventComponentsSaga(
  action: ReduxAction<ActionProps<ZCustomEvent[]>>,
) {
  try {
    const { componentData, extensionId } = action?.payload;
    const { data }: CustomEventComponentResponse = yield call(
      ZuoraCustomEventComponentsApi.saveCustomEvents,
      componentData,
      extensionId,
    );
    yield put(saveCustomEventsSuccess(data));
  } catch (error) {
    yield put(saveCustomEventsFailure());
  }
}

export function* loadCustomEventComponentsSaga(
  action: ReduxAction<{ extensionId?: string }>,
) {
  try {
    const { data }: CustomEventComponentResponse = yield call(
      ZuoraCustomEventComponentsApi.loadCustomEvents,
      action?.payload.extensionId,
    );
    yield put(loadCustomEventsSuccess(data));
  } catch (error) {
    yield put(loadCustomEventsFailure());
  }
}

export function* saveWorkflowComponentsSaga(
  action: ReduxAction<ActionProps<ZWorkflow[]>>,
) {
  try {
    const { componentData, extensionId } = action?.payload;
    const { data }: WorkflowComponentResponse = yield call(
      ZuoraWorkflowComponentsApi.saveWorkflows,
      componentData,
      extensionId,
    );
    yield put(saveWorkflowsSuccess(data));
  } catch (error) {
    yield put(saveWorkflowsFailure());
  }
}

export function* loadWorkflowComponentsSaga(
  action: ReduxAction<{ extensionId?: string }>,
) {
  try {
    const { data }: WorkflowComponentResponse = yield call(
      ZuoraWorkflowComponentsApi.loadWorkflows,
      action?.payload.extensionId,
    );
    yield put(loadWorkflowsSuccess(data));
  } catch (error) {
    yield put(loadWorkflowsFailure());
  }
}
