import { select } from "redux-saga/effects";
import type { AppState } from "ee/reducers";

export default function* getSessionDataSaga() {
  try {
    const response: unknown = yield select(
      (state: AppState) => state.entities.app.sessionData,
    );
    return response;
  } catch (error) {
    throw error;
  }
}
