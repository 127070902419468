import store from "store";
import type { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { is404orAuthPath } from "api/helpers";
import { ZAUTH_LOGIN_URL } from "constants/routes";
import { API_STATUS_CODES, ERROR_CODES } from "ee/constants/ApiConstants";
import { logoutZUser } from "actions/zuoraActions";

export const handleUnauthorizedError = async (error: AxiosError) => {
  if (is404orAuthPath()) return null;

  if (
    error.response?.status === API_STATUS_CODES.REQUEST_NOT_AUTHORISED &&
    !error.config?.url?.includes("workflows")
  ) {
    const currentUrl = `${window.location.href}`;
    store.dispatch(
      logoutZUser({
        redirectURL: `${ZAUTH_LOGIN_URL}?redirectUrl=${encodeURIComponent(
          currentUrl,
        )}`,
      }),
    );

    Sentry.captureException(error);

    return Promise.reject({
      ...error,
      code: ERROR_CODES.REQUEST_NOT_AUTHORISED,
      message: "Unauthorized. Redirecting to login page...",
      show: false,
    });
  }

  return null;
};
