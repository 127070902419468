import TriggerEmitter, { BatchKey } from "./utils/TriggerEmitter";

function submitHpmDescriptor() {
  return {
    type: "SUBMIT_HPM" as const,
    payload: null,
  };
}

export type TSubmitHpmArgs = Parameters<typeof submitHpmDescriptor>;
export type TSubmitHpmDescription = ReturnType<typeof submitHpmDescriptor>;
export type TSubmitHpmActionType = TSubmitHpmDescription["type"];

async function submitHpm() {
  TriggerEmitter.emit(BatchKey.process_batched_triggers, {
    trigger: submitHpmDescriptor(),
  });
}

export default submitHpm;
