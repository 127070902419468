/* eslint-disable no-console */
import { put } from "redux-saga/effects";
import { toast } from "@appsmith/ads";
import log from "loglevel";
import { fetchZuoraEnvironment } from "utils/URLUtils";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import WFActionAPI from "ee/api/WFActionAPI";
import type { WFInstance } from "entities/WFCollection";

export default function* runZuoraWorkflow(
  wfDefinitionId: string,
  triggerSourceId: string,
  config: any,
  additionalArgs: any[],
) {
  try {
    let response: WFInstance;
    // no params to be found
    const payload = new FormData();
    const parameters = config?.parameters;

    if (parameters && parameters.length && additionalArgs) {
      parameters.forEach(
        (parameter: { callout_id: string; value: string; index: number }) =>
          payload.append(
            parameter.callout_id,
            additionalArgs[parameter.index] as string,
          ),
      );

      response = yield WFActionAPI.runWFAction(wfDefinitionId, payload);
    } else {
      response = yield WFActionAPI.runWFAction(wfDefinitionId, null);
    }

    if (response.name === "AxiosError") {
      throw new Error("Workflow execution failed");
    }

    console.log(response);
    toast.show("Workflow successfully queued", {
      kind: "success",
      action: {
        text: "View",
        effect: () => {
          const env = fetchZuoraEnvironment();
          let url;
          if (env === "local") {
            url = "http://localhost:3001";
          } else if (env === "stg") {
            url = "/services/workflow-staging";
          } else {
            url = "/services/workflow";
          }
          window.open(
            `${url}/workflows/${response.originalWorkflowId}/executions/${response.id}`,
            "_blank",
          );
        },
      },
    });
    yield put({
      type: ReduxActionTypes.RUN_WF_ACTION_SUCCESS,
      payload: response,
    });
  } catch (e: any) {
    log.error(e);
    yield put({
      type: ReduxActionTypes.RUN_WF_ACTION_FAILURE,
      payload: e,
    });
    throw e;
    // log.error(e);
    // for (const err of e.errors) {
    //   yield put(showZuoraToast(err.title || err.message, { kind: "error" }));
    // }
  }
}
