import { call, put, select } from "redux-saga/effects";
import { getPageList } from "selectors/editorSelectors";
import _ from "lodash";
import type { Page } from "entities/Page";
import { getAppMode } from "ee/selectors/applicationSelectors";
import { APP_MODE } from "entities/App";
import AppsmithConsole from "utils/AppsmithConsole";
import { builderURL, viewerURL } from "ee/RouteBuilder";
import type { ApiResponse } from "api/ApiResponses";
import UserApi from "ee/api/UserApi";
import { validateResponse } from "sagas/ErrorSagas";
import localStorage from "utils/localStorage";
import type { User } from "constants/userConstants";
import { getCurrentUser } from "selectors/usersSelectors";
import { logoutUserSuccess } from "actions/userActions";
import UsagePulse from "usagePulse";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import { TriggerFailureError } from "./ActionExecution/errorUtils";
import type { TLogoutDescription } from "workers/Evaluation/fns/logout";

const isValidPageName = (
  pageName: string,
  pageList: Page[],
): Page | undefined => {
  return _.find(pageList, (page: Page) => page.pageName === pageName);
};

export default function* logoutActionSaga(action: TLogoutDescription) {
  const { payload } = action;
  const pageList: Page[] = yield select(getPageList);
  const { pageName } = payload;

  const page = isValidPageName(pageName, pageList);

  if (page) {
    const appMode: APP_MODE = yield select(getAppMode);
    const path =
      appMode === APP_MODE.EDIT
        ? builderURL({
            basePageId: page.basePageId,
          })
        : viewerURL({
            basePageId: page.basePageId,
          });

    if (appMode === APP_MODE.PUBLISHED) {
      const response: ApiResponse = yield call(UserApi.logoutUser);
      const isValidResponse: boolean = yield validateResponse(response);

      if (isValidResponse) {
        UsagePulse.stopTrackingActivity();
        AnalyticsUtil.reset();
        const currentUser: User | undefined = yield select(getCurrentUser);

        yield put(logoutUserSuccess(!!currentUser?.emptyInstance));
        localStorage.clearExceptZItems();
      } else {
        throw new TriggerFailureError("Invalid response from logout API");
      }
    }

    window.location.href = path;
    AppsmithConsole.info({
      text: `logout and navigate to '${page.pageName}' was triggered`,
      state: {},
    });
  } else {
    throw new TriggerFailureError("Selected page is invalid");
  }
}
