/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-restricted-imports */
import {
  loadCustomEvents,
  loadCustomFields,
  loadObjectComponents,
  loadWorkflows,
} from "ce/actions/zuoraActions";
import type { ZAvailableExtensions } from "ce/types/zuora";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

export const useLoadComponents = ({
  extensionId: _extensionId,
}: {
  extensionId: string;
}) => {
  const extensionIdRef = useRef<string>("");

  useEffect(() => {
    if (_extensionId) {
      extensionIdRef.current = _extensionId;
    }
  }, [_extensionId]);

  const getExtensionId = () => {
    return extensionIdRef.current;
  };

  const dispatch = useDispatch();

  const loadCustomField = () => {
    dispatch(loadCustomFields(getExtensionId()));
  };

  const loadObject = () => {
    dispatch(loadObjectComponents(getExtensionId()));
  };

  const loadCustomEvent = () => {
    dispatch(loadCustomEvents(getExtensionId()));
  };

  const loadWorkflow = () => {
    dispatch(loadWorkflows(getExtensionId()));
  };

  const loadComponents = (componentType: ZAvailableExtensions) => {
    switch (componentType) {
      case "customField":
        return loadCustomField();

      case "customObject":
        return loadObject();

      case "customEvent":
        return loadCustomEvent();

      case "workflow":
        return loadWorkflow();

      default:
        console.error("Invalid component type");
    }
  };

  return { loadComponents };
};
