export * from "ce/sagas/WFActionSagas";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { fetchWFCollectionsSaga } from "ce/sagas/WFActionSagas";
import { all, takeEvery } from "redux-saga/effects";

export function* watchWFActionSagas() {
  yield all([
    takeEvery(ReduxActionTypes.FETCH_WF_ACTIONS_INIT, fetchWFCollectionsSaga),
  ]);
}
