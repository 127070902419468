/* eslint-disable no-console */
import React from "react";
import { ConditionItem } from "./ConditionItem";
import { Button, Select, SelectItem } from "zds";
import _ from "lodash";
import styled from "styled-components";

// Styled components
const ConditionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 128px;
`;

const ConditionVerticalLine = styled.span`
  position: absolute;
  top: 38px;
  bottom: 45px;
  left: 56px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
`;

const ConditionParentHLine = styled.span`
  position: absolute;
  height: 1px;
  top: 50%;
  width: 94px;
  background-color: rgba(0, 0, 0, 0.2);
  left: -72px;
`;

const ConditionParentELine = styled.span`
  position: absolute;
  height: 50%;
  top: 0;
  width: 2px;
  background-color: #ffffff;
  left: -72px;
`;

const ConditionAdd = styled.div`
  position: relative;
  width: 505px;
  padding: 22px 20px;
  background-color: #f6f7f8;
  border-radius: 4px;
  margin-bottom: 8px;
`;

const ArrowIcon = styled.span`
  position: absolute;
  color: rgba(0, 0, 0, 0.24);
  font-size: 10px;
  top: 32px;
  left: -20px;
`;

const ArrowLine = styled.span`
  position: absolute;
  top: 38px;
  left: -71px;
  height: 1px;
  width: 52px;
  background-color: rgba(0, 0, 0, 0.2);
`;

const ConditionRelation = styled.div`
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 10;
  fieldset {
    background-color: #ffffff;
  }
`;

export const Condition = (props) => {
  return (
    <ConditionContainer>
      <ConditionVerticalLine />
      {/* horizon line */}
      {props.isRoot !== false ? null : <ConditionParentHLine />}
      {/* eraser to remove parent vertical line when condition position first item this is a trick solution,
      condition-parent-e-line bg color must be the same as its container */}
      {props.index.endsWith("-0") ? <ConditionParentELine /> : null}
      <ConditionRelation
        style={_.isEmpty(props.data) ? { display: "none" } : {}}
      >
        <Select
          dsOnChange={(e) => {
            console.log(e.target.value);
            props.updateCondition(
              props.index,
              "update",
              "relation",
              e.target.value,
            );
          }}
          value={props.relation}
        >
          <SelectItem value="AND">And</SelectItem>
          <SelectItem value="OR">&nbsp;Or&nbsp;&nbsp;</SelectItem>
        </Select>
      </ConditionRelation>
      {(props.data || []).map((c, index) => {
        return (
          <div key={`${c.field}-${index}`}>
            {/* recursive part if condition has conditions property then recursively render it */}
            {c.conditions ? (
              <Condition
                data={c.conditions}
                index={`${props.index}-${index}`}
                isRoot={false}
                objectFieldSelectProps={props.objectFieldSelectProps}
                querySelection={props.querySelection}
                relation={c.relation}
                updateCondition={props.updateCondition}
              />
            ) : (
              <ConditionItem
                conditionData={c}
                index={`${props.index}-${index}`}
                objectFieldSelectProps={props.objectFieldSelectProps}
                updateCondition={props.updateCondition}
              />
            )}
          </div>
        );
      })}
      <ConditionAdd>
        <ArrowIcon style={_.isEmpty(props.data) ? { display: "none" } : {}}>
          ▶
        </ArrowIcon>
        <ArrowLine style={_.isEmpty(props.data) ? { display: "none" } : {}} />

        <Button
          dsOnClick={() => {
            props.updateCondition(props.index, "add");
          }}
          startIcon="add"
          variant="outlined"
        >
          Add Condition
        </Button>
      </ConditionAdd>
    </ConditionContainer>
  );
};
