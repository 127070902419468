import { createReducer } from "utils/ReducerUtils";
import type {
  WFCollection,
  WFDefinition,
  WFInstance,
} from "entities/WFCollection";
import type { ReduxAction } from "ee/constants/ReduxActionConstants";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";

export const initialState: WFCollectionDataState = [];

export interface WFCollectionData {
  isLoading: boolean;
  config: WFCollection;
  data?: Record<string, unknown>;
  isExecuting?: Record<string, boolean>;
  // activeWFActionId?: string;
  // Existence of parse errors for each action (updates after execution)
  // isDirty?: Record<string, boolean>;
}
export type WFCollectionDataState = WFCollectionData[];

export const handlers = {
  [ReduxActionTypes.FETCH_WF_ACTIONS_SUCCESS]: (
    state: WFCollectionDataState,
    action: ReduxAction<WFDefinition[]>,
  ): WFCollectionDataState => {
    return action.payload.map((action) => {
      const foundAction = state.find((currentAction) => {
        return currentAction.config.id === action.id;
      });
      return {
        isLoading: false,
        config: {
          active_version: {
            id: action.active_version.id,
            datatypeOptions: action.active_version.datatypeOptions || {},
            parameters: action.active_version.parameters || [],
            useLegacyBooleanInput:
              action.active_version.useLegacyBooleanInput || false,
          },
          id: action.id,
          name: action.name,
        },
        data: foundAction?.data,
      };
    });
  },
  [ReduxActionErrorTypes.FETCH_WF_ACTIONS_ERROR]: () => initialState,
  [ReduxActionTypes.RUN_WF_ACTION_SUCCESS]: (
    state: WFCollectionDataState,
    wfInstance: ReduxAction<WFInstance>,
  ): WFCollectionDataState =>
    state.map((wfAction) => {
      const wfDefinitionId = wfAction.config.id;
      if (wfInstance.payload.definitionId === wfDefinitionId) {
        let data = {
          ...wfAction.data,
        };
        data = {
          ...data,
          [wfInstance.payload.id]: {
            createdAt: wfInstance.payload.createdAt,
            status: wfInstance.payload.status,
          },
        };
        return {
          ...wfAction,
          data,
        };
      }
      return wfAction;
    }),
};

const wfActionsReducer = createReducer(initialState, handlers);

export default wfActionsReducer;
