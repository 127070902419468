import { createReducer } from "utils/ReducerUtils";
import type { ReduxAction } from "ee/constants/ReduxActionConstants";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { User } from "constants/userConstants";
import type { APP_MODE } from "entities/App";

export interface AuthUserState {
  username: string;
  email: string;
  id: string;
}

export interface UrlDataState {
  queryParams: Record<string, string>;
  protocol: string;
  host: string;
  hostname: string;
  port: string;
  pathname: string;
  hash: string;
  fullPath: string;
  referrer: string;
}

export type AppStoreState = Record<string, unknown>;
export type ZuoraState = Record<string, unknown>;
export type SessionDataState = Record<string, unknown>;
export type ZConstantsState = Record<string, object>;
export type ZSystemVarsState = Record<string, object>;
export type ZCustomVarsState = Record<string, object>;

export interface AppDataState {
  mode?: APP_MODE;
  user: AuthUserState;
  URL: UrlDataState;
  store: AppStoreState;
  geolocation: {
    canBeRequested: boolean;
    currentPosition?: Partial<GeolocationPosition>;
  };
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workflows: Record<string, any>;
  zuora: ZuoraState;
  sessionData: SessionDataState;
  zConstants: ZConstantsState;
  zSystemVars: ZSystemVarsState;
}

const initialState: AppDataState = {
  user: {
    username: "",
    email: "",
    id: "",
  },
  URL: {
    queryParams: {},
    protocol: "",
    host: "",
    hostname: "",
    port: "",
    pathname: "",
    hash: "",
    fullPath: "",
    referrer: "",
  },
  store: {},
  geolocation: {
    canBeRequested: "geolocation" in navigator,
    currentPosition: {},
  },

  workflows: {},
  zuora: {},
  sessionData: {},
  zConstants: {},
  zSystemVars: {},
};

const appReducer = createReducer(initialState, {
  [ReduxActionTypes.SET_APP_MODE]: (
    state: AppDataState,
    action: ReduxAction<APP_MODE>,
  ) => {
    return {
      ...state,
      mode: action.payload,
    };
  },
  [ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS]: (
    state: AppDataState,
    action: ReduxAction<User>,
  ) => {
    return {
      ...state,
      user: action.payload,
    };
  },
  [ReduxActionTypes.SET_URL_DATA]: (
    state: AppDataState,
    action: ReduxAction<UrlDataState>,
  ) => {
    return {
      ...state,
      URL: action.payload,
    };
  },
  [ReduxActionTypes.UPDATE_APP_STORE]: (
    state: AppDataState,
    action: ReduxAction<Record<string, unknown>>,
  ) => {
    return {
      ...state,
      store: action.payload,
    };
  },
  [ReduxActionTypes.SET_USER_CURRENT_GEO_LOCATION]: (
    state: AppDataState,
    action: ReduxAction<{ position: GeolocationPosition }>,
  ): AppDataState => {
    return {
      ...state,
      geolocation: {
        ...state.geolocation,
        currentPosition: action.payload.position,
      },
    };
  },
  [ReduxActionTypes.SET_ZUORA_SESSION_DATA_SUCCESS]: (
    state: ZuoraState,
    action: ReduxAction<Record<string, unknown>>,
  ) => {
    return {
      ...state,
      sessionData: action.payload,
    };
  },
  [ReduxActionTypes.FETCH_ZENVCONSTANTS_SUCCESS]: (
    state: AppDataState,
    action: ReduxAction<Record<string, object>>,
  ) => {
    return {
      ...state,
      zSystemVars: action.payload.systemEnvironmentVariables,
    };
  },
  [ReduxActionTypes.FETCH_ZUORA_GENESIS_INFO_SUCCESS]: (
    state: ZuoraState,
    action: ReduxAction<Record<string, object>>,
  ) => {
    return {
      ...state,
      zuora: action.payload,
    };
  },
  [ReduxActionTypes.FETCH_ZCONSTANTS_SUCCESS]: (
    state: ZConstantsState,
    action: ReduxAction<Record<string, object>>,
  ) => {
    return {
      ...state,
      zConstants: action.payload.zConstantsConsolidated,
    };
  },
  [ReduxActionTypes.CHANGE_APP_ZCUSTOMVARS_SUCCESS]: (
    state: ZConstantsState,
    action: ReduxAction<Record<string, object>>,
  ) => {
    return {
      ...state,
      zCustomVars: action.payload.zCustomVars,
    };
  },
});

export default appReducer;
