import type { AppState } from "ee/reducers";
import React from "react";
import { useSelector } from "react-redux";
import { ToastController } from "zds";

const ZToastComponent: React.FC = () => {
  const {
    message,
    open,
    severity = "error",
  } = useSelector((state: AppState) => state.ui.zuoraToast);

  return (
    <ToastController
      keyRender={Date.now()}
      message={message}
      {...(open ? {} : { duration: 0 })}
      severity={severity}
    />
  );
};

export default ZToastComponent;
