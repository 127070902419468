import { promisify } from "./utils/Promisify";

function getSessionDataFnDescriptor() {
  return {
    type: "GET_SESSION_DATA" as const,
    payload: {},
  };
}

export type TGetSessionDataArgs = Parameters<typeof getSessionDataFnDescriptor>;
export type TGetSessionDataDescription = ReturnType<
  typeof getSessionDataFnDescriptor
>;
export type TGetSessionDataActionType = TGetSessionDataDescription["type"];

async function getSessionData() {
  let response = {};
  const executor = promisify(getSessionDataFnDescriptor);
  try {
    response = await executor();
  } catch (e) {
    throw e;
  }
  return response;
}

export default getSessionData;
