/* eslint-disable @typescript-eslint/no-restricted-imports */
import { setIsComponentManagerLoaded } from "ce/actions/zuoraActions";
import {
  COMPONENT_MANAGER_LOADED,
  LINK_COMPONENTS,
  SAME_ORIGIN,
} from "ce/constants/zuora/PostMessageConstants";
import type { AppState } from "ce/reducers";
import type { ZComponentManagerPostMessageData } from "ce/types/zuora";
import React, {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { isInDevEnvironment } from "utils/isInDevEnvironment";
import { ZComponentPersistenceContext } from "ZComponentPersistenceProvider";
import { isValidOrigin, targetDevOrigin } from "./isValidOrigin";

interface ZPostMessageContext {
  addMessageToQueue: (message: PostMessageData) => void;
}

const ZPostMessageContext = createContext({} as ZPostMessageContext);

interface ZPostMessageProviderProps {
  children: ReactNode;
}

interface PostMessageData {
  type: string;
  payload?: unknown;
}

const ZPostMessageProvider: React.FC<ZPostMessageProviderProps> = ({
  children,
}) => {
  const componentManagerIsLoaded: boolean = useSelector(
    (state: AppState) => state.ui.zuoraComponents.componentManagerIsLoaded,
  );

  const [messageQueue, setMessageQueue] = React.useState<PostMessageData[]>([]);

  const dispatch = useDispatch();

  const { saveComponents } = useContext(ZComponentPersistenceContext);

  // wait for component manager to load before sending messages
  useEffect(() => {
    if (messageQueue.length === 0 || !componentManagerIsLoaded) {
      return;
    }

    const messages: PostMessageData[] = [...messageQueue];

    messages.forEach((message: PostMessageData) => {
      sendMessage(message);
    });

    setMessageQueue([]);
  }, [componentManagerIsLoaded, messageQueue]);

  const sendMessage = ({ payload, type }: PostMessageData) => {
    const targetOrigin = isInDevEnvironment ? targetDevOrigin : SAME_ORIGIN;

    if (!targetOrigin) {
      return;
    }

    const iframe: HTMLIFrameElement = document.getElementById(
      "platform-ui-extension-studio-component-manager",
    ) as HTMLIFrameElement;

    const storybookWindow = iframe.contentWindow;

    storybookWindow?.postMessage(
      {
        type,
        payload,
      },
      targetOrigin,
    );
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent<PostMessageData>) => {
      if (!isValidOrigin(event.origin)) {
        return;
      }

      const messageType: string = event.data?.type;

      switch (messageType) {
        case COMPONENT_MANAGER_LOADED:
          dispatch(setIsComponentManagerLoaded(true));
          break;

        case LINK_COMPONENTS:
          saveComponents(
            event.data?.payload as ZComponentManagerPostMessageData,
          );
          break;

        default:
          break;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const addMessageToQueue = (message: PostMessageData) => {
    setMessageQueue((prev) => [...prev, message]);
  };

  return (
    <ZPostMessageContext.Provider
      value={{
        addMessageToQueue,
      }}
    >
      {children}
    </ZPostMessageContext.Provider>
  );
};

export { ZPostMessageContext, ZPostMessageProvider };
