import type { AxiosPromise } from "axios";
import Api from "../Api";
import type {
  FetchTemplatesResponse,
  ImportTemplateResponse,
} from "../TemplatesApi";
import TemplatesAPI from "../TemplatesApi";

class ZtemplatesAPI extends Api {
  static baseUrl = "v1";

  static async getAllZtemplates(): Promise<
    AxiosPromise<FetchTemplatesResponse>
  > {
    return Api.get(TemplatesAPI.baseUrl + `/ztemplates`);
  }

  static async getZTemplateInformation(
    templateId: string,
  ): Promise<AxiosPromise<FetchTemplatesResponse>> {
    return Api.get(TemplatesAPI.baseUrl + `/ztemplates/${templateId}`);
  }

  static async importZTemplate(
    templateId: string,
    workspaceId: string,
  ): Promise<AxiosPromise<ImportTemplateResponse>> {
    // const dsNames = {
    //   "Zuora": 'Zuora Fork',
    //   // Add more datasource names and their new names as needed
    // };
    // const config = {
    //   "dsNames": dsNames,
    //   "isPublic": true,
    //   "name": "doneapp",
    //   "constants": {
    //     "hpmId": "target",
    //   },
    // }
    const url =
      TemplatesAPI.baseUrl + `/ztemplates/${templateId}/zimport/${workspaceId}`;
    return Api.post(
      url,
      // JSON.stringify(config),
    );
  }
}

export default ZtemplatesAPI;
