import type { ZToastOptions, ZToastType } from "./types";

export const SHOW_ZTOAST = "SHOW_SNACKBAR";
export const HIDE_ZTOAST = "HIDE_SNACKBAR";

export interface ShowZToastAction {
  type: typeof SHOW_ZTOAST;
  payload: {
    message: string;
    severity: ZToastType;
  };
}

export interface HideZToastAction {
  type: typeof HIDE_ZTOAST;
}

export const showZuoraToast = (
  message: string,
  options: ZToastOptions = { kind: "success" },
): ShowZToastAction => ({
  type: SHOW_ZTOAST,
  payload: { message, severity: options.kind },
});

export const hideZuoraToast = (): HideZToastAction => ({
  type: HIDE_ZTOAST,
});
