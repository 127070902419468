import React from "react";
import type { AppState } from "ee/reducers";
import { getCurrentUser, getUserAuthError } from "selectors/usersSelectors";
import { connect } from "react-redux";
import type { User } from "constants/userConstants";
import { ANONYMOUS_USERNAME } from "constants/userConstants";
import { Redirect } from "react-router";
import {
  APPLICATIONS_URL,
  BASE_EXTENSIONS_URL,
  ZAUTH_LOGIN_URL,
} from "constants/routes";
import PageLoadingBar from "pages/common/PageLoadingBar";
import ServerUnavailable from "pages/common/ErrorPages/ServerUnavailable";
import { logoutZUser } from "actions/zuoraActions";

interface Props {
  user?: User;
  authError: string;
  dispatchLogout: () => void;
}

function Zextensions(props: Props) {
  // const [loading, setLoading] = useState(true);
  const pathname = window.location.pathname;

  // useEffect(() => {
  //   let zuoraEmail;
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     if (key && key.includes("ZNavIdentity"))
  //       zuoraEmail = JSON.parse(localStorage[key])["username"];
  //   }
  //   if (props.user?.email !== zuoraEmail) {
  //     props.dispatchLogout();
  //   }
  //   setLoading(false);
  // }, [props.dispatchLogout, pathname]);

  // if (loading) {
  //   return <PageLoadingBar />;
  // }
  if (props.user && pathname === BASE_EXTENSIONS_URL) {
    if (props.user.email === ANONYMOUS_USERNAME) {
      return <Redirect to={ZAUTH_LOGIN_URL} />;
    } else {
      return <Redirect to={APPLICATIONS_URL} />;
    }
  }

  if (props.authError && props.authError.length) {
    return <ServerUnavailable />;
  }

  return <PageLoadingBar />;
}

const mapStateToProps = (state: AppState) => ({
  user: getCurrentUser(state),
  authError: getUserAuthError(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchLogout: () => dispatch(logoutZUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Zextensions);
