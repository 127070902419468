import type { ZToastType } from "./types";
import {
  SHOW_ZTOAST,
  HIDE_ZTOAST,
  type ShowZToastAction,
  type HideZToastAction,
} from "./ZToast.actions";

export interface ZToastState {
  open: boolean;
  message: string;
  severity: ZToastType;
}

const initialState: ZToastState = {
  open: false,
  message: "",
  severity: "success",
};

const ztoastReducer = (
  state = initialState,
  action: ShowZToastAction | HideZToastAction,
): ZToastState => {
  switch (action.type) {
    case SHOW_ZTOAST:
      return {
        ...state,
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    case HIDE_ZTOAST:
      return {
        ...state,
        open: false,
        message: "",
        severity: "info",
      };
    default:
      return state;
  }
};

export default ztoastReducer;
