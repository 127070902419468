import { LOGIN_SETTING, createMessage } from "ee/constants/messages";
import { ssoOptions } from "ee/constants/SocialLogin";
import { Text, Switch, Select, Option, Input, Divider } from "@appsmith/ads";
import React, { useState } from "react";
import styled from "styled-components";
import PropertyHelpLabel from "pages/Editor/PropertyPane/PropertyHelpLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentApplication,
  getIsChangingSsoLogin,
  getIsChangingSsoCustomPageId,
  getIsChangingSsoLoginTypes,
  getIsChangingSsoUnauthedPageIds,
  getIsChangingSsoLandingPageId,
} from "ee/selectors/applicationSelectors";
import { getIsFetchingApplications } from "ee/selectors/selectedWorkspaceSelectors";
import {
  changeAppSsoLoginInit,
  changeAppSsoCustomPageInit,
  changeAppSsoLoginTypesInit,
  changeAppSsoApprovedDomainsInit,
  changeAppSsoApprovedUsersInit,
  changeAppSsoUnauthedPageIdsInit,
  changeAppSsoLandingPageIdInit,
} from "ee/actions/applicationActions";

const SettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const Title = styled.p`
  font-size: var(--ads-v2-font-size-4);
  line-height: 1.2rem;
  font-weight: var(--ads-v2-font-weight-bold);
  color: var(--ads-v2-color-fg-emphasis);
`;

const StyledPropertyHelpLabel = styled(PropertyHelpLabel)`
  .bp3-popover-content > div {
    text-align: center;
    max-height: 44px;
    display: flex;
    align-items: center;
  }
`;

export function LoginSettings() {
  const dispatch = useDispatch();
  const application = useSelector(getCurrentApplication);
  const isFetchingApplication = useSelector(getIsFetchingApplications);
  const isChangingSsoLogin = useSelector(getIsChangingSsoLogin);
  const isChangingSsoCustomPageId = useSelector(getIsChangingSsoCustomPageId);
  const isChangingSsoLoginTypes = useSelector(getIsChangingSsoLoginTypes);
  const isChangingSsoUnauthedPageIds = useSelector(
    getIsChangingSsoUnauthedPageIds,
  );
  const isChangingSsoLandingPageId = useSelector(getIsChangingSsoLandingPageId);
  const multiselectEnabled = true;
  const allowClear = true;
  const [approvedDomains, setApprovedDomains] = useState(
    application?.ssoLoginSetting?.approvedDomains,
  );
  const [approvedUsers, setApprovedUsers] = useState(
    application?.ssoLoginSetting?.approvedUsers,
  );

  return (
    <>
      <div className="px-4 mt-4 mb-3">
        <Title>{createMessage(LOGIN_SETTING.settingHeader)}</Title>
      </div>
      {!application?.isPublic && (
        <SettingWrapper>
          <Text kind="body-m">{createMessage(LOGIN_SETTING.privateDesc)}</Text>
        </SettingWrapper>
      )}
      {application?.isPublic && (
        <div className="px-4">
          <div className="flex justify-between content-center">
            <Switch
              data-testid="t--login-settings-application-enabled"
              isDisabled={isFetchingApplication || isChangingSsoLogin}
              isSelected={application?.ssoLoginSetting?.ssoLoginEnabled}
              onChange={() => {
                application &&
                  dispatch(
                    changeAppSsoLoginInit(
                      application?.id,
                      !application?.ssoLoginSetting?.ssoLoginEnabled,
                    ),
                  );
              }}
            >
              <StyledPropertyHelpLabel
                label={createMessage(LOGIN_SETTING.enableSocialSso)}
                lineHeight="1.17"
                maxWidth="270px"
                tooltip={createMessage(LOGIN_SETTING.enableSocialSsoTooltip)}
              />
            </Switch>
          </div>
        </div>
      )}
      {application?.isPublic &&
        application?.ssoLoginSetting?.ssoLoginEnabled && (
          <div>
            <div className="px-4 mt-4 mb-3">
              {createMessage(LOGIN_SETTING.enabledLogins)}
            </div>
            <div className="px-4">
              <div className="flex justify-between content-center">
                <section className="space-y-2">
                  <Select
                    dropdownClassName="t--theme-font-dropdown"
                    isDisabled={
                      isFetchingApplication || isChangingSsoLoginTypes
                    }
                    isMultiSelect={multiselectEnabled}
                    onChange={(value: string[]) => {
                      application &&
                        dispatch(
                          changeAppSsoLoginTypesInit(application?.id, value),
                        );
                    }}
                    value={application?.ssoLoginSetting?.enabledLoginTypes}
                  >
                    {Object.entries(ssoOptions).map(([key, value]) => (
                      <Option key={key} value={value}>
                        <div className="flex space-x-2 w-full cursor-pointer items-center">
                          <div className="leading-normal">{key}</div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </section>
              </div>
            </div>
            <Divider />
            <div className="px-4 mt-4 mb-3">
              {createMessage(LOGIN_SETTING.enableCustomSsoPage)}
            </div>
            <div className="px-4">
              <div className="flex justify-between content-center">
                <section className="space-y-2">
                  <Select
                    allowClear={allowClear}
                    dropdownClassName="t--theme-font-dropdown"
                    isDisabled={
                      isFetchingApplication || isChangingSsoCustomPageId
                    }
                    onChange={(value: string) => {
                      application &&
                        dispatch(
                          changeAppSsoCustomPageInit(application?.id, value),
                        );
                    }}
                    value={application?.ssoLoginSetting?.customSsoPageName}
                  >
                    {application?.pages.map((page) => (
                      <Option key={page.name} value={page.slug}>
                        <div className="flex space-x-2 w-full cursor-pointer items-center">
                          <div className="leading-normal">{page.name}</div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </section>
              </div>
            </div>
            <Divider />
            <div className="px-4">
              <div className="flex justify-between content-center">
                <Input
                  id="t--sso-settings-approved-domains"
                  label={createMessage(LOGIN_SETTING.approvedDomains)}
                  onBlur={() => {
                    application &&
                      dispatch(
                        changeAppSsoApprovedDomainsInit(
                          application?.id,
                          approvedDomains,
                        ),
                      );
                  }}
                  onChange={(value: string) => {
                    setApprovedDomains(value.replace(/\s/g, "").split(","));
                  }}
                  onKeyPress={(ev: React.KeyboardEvent) => {
                    if (ev.key === "Enter") {
                      application &&
                        dispatch(
                          changeAppSsoApprovedDomainsInit(
                            application?.id,
                            approvedDomains,
                          ),
                        );
                    }
                  }}
                  placeholder="example.com"
                  size="md"
                  type="text"
                  value={approvedDomains?.join(", ")}
                />
              </div>
            </div>
            <br />
            <div className="px-4">
              <div className="flex justify-between content-center">
                <Input
                  id="t--sso-settings-approved-users"
                  label={createMessage(LOGIN_SETTING.approvedUsers)}
                  onBlur={() => {
                    application &&
                      dispatch(
                        changeAppSsoApprovedUsersInit(
                          application?.id,
                          approvedUsers,
                        ),
                      );
                  }}
                  onChange={(value: string) => {
                    setApprovedUsers(value.replace(/\s/g, "").split(","));
                  }}
                  onKeyPress={(ev: React.KeyboardEvent) => {
                    if (ev.key === "Enter") {
                      application &&
                        dispatch(
                          changeAppSsoApprovedUsersInit(
                            application?.id,
                            approvedUsers,
                          ),
                        );
                    }
                  }}
                  placeholder="example@email.com"
                  size="md"
                  type="text"
                  value={approvedUsers?.join(", ")}
                />
              </div>
            </div>
            <Divider />
            <div className="px-4 mt-4 mb-3">
              {createMessage(LOGIN_SETTING.unauthedPages)}
            </div>
            <div className="px-4">
              <div className="flex justify-between content-center">
                <section className="space-y-2">
                  <Select
                    dropdownClassName="t--theme-font-dropdown"
                    isDisabled={
                      isFetchingApplication || isChangingSsoUnauthedPageIds
                    }
                    isMultiSelect={multiselectEnabled}
                    onChange={(value: string[]) => {
                      application &&
                        dispatch(
                          changeAppSsoUnauthedPageIdsInit(
                            application?.id,
                            value,
                          ),
                        );
                    }}
                    value={application?.ssoLoginSetting?.unauthedPageNames}
                  >
                    {application?.pages.map((page) => (
                      <Option key={page.name} value={page.slug}>
                        <div className="flex space-x-2 w-full cursor-pointer items-center">
                          <div className="leading-normal">{page.name}</div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </section>
              </div>
            </div>
            <div className="px-4 mt-4 mb-3">
              {createMessage(LOGIN_SETTING.landingPage)}
            </div>
            <div className="px-4">
              <div className="flex justify-between content-center">
                <section className="space-y-2">
                  <Select
                    dropdownClassName="t--theme-font-dropdown"
                    isDisabled={
                      isFetchingApplication || isChangingSsoLandingPageId
                    }
                    onChange={(value: string) => {
                      application &&
                        dispatch(
                          changeAppSsoLandingPageIdInit(application?.id, value),
                        );
                    }}
                    value={application?.ssoLoginSetting?.landingPageName}
                  >
                    {application?.pages.map((page) => (
                      <Option key={page.name} value={page.slug}>
                        <div className="flex space-x-2 w-full cursor-pointer items-center">
                          <div className="leading-normal">{page.name}</div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </section>
              </div>
            </div>
          </div>
        )}
    </>
  );
}
