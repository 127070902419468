/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Grid, Typography } from "zds";
import ControlledTreeView from "./ControlledTreeView";
import { buildClientSchema } from "graphql";
import FieldsSelection from "./FieldsSelection";
import { ConditionTab } from "./ConditionTab";
import {
  getLinkedObjects,
  queryBuilder,
  setToValue,
  sortFunc,
  removeEmptyFilterConditions,
  filterFieldsFunc,
  getFieldLists,
  orderByFieldsFunc,
} from "./Utils";

import _ from "lodash";

import { Tab, Tabs, TabsList, toast, Select, Option } from "@appsmith/ads";
import introspectionSchemaResult from "./IntrospecSchema.json";
import styled from "styled-components";
import graphSchemaRequest from "./graphSchemaRequest";
import ZGraphQLAPI from "ee/api/ZGraphQLAPI";
import {
  CodeEditorBorder,
  EditorSize,
  EditorTheme,
} from "../../../../components/editorComponents/CodeEditor/EditorConfig";
import LazyCodeEditor from "../../../../components/editorComponents/LazyCodeEditor";
import { bindingHintHelper } from "../../../../components/editorComponents/CodeEditor/hintHelpers";
import { slashCommandHintHelper } from "../../../../components/editorComponents/CodeEditor/commandsHelper";
import DynamicTextField from "../../../../components/editorComponents/form/fields/DynamicTextField";

const EmptyObject = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const BodyWrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  padding-bottom: 16px;
  overflow: auto;
  min-height: 500px;
`;

const GraphqueryQueryTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  border: solid rgb(205, 213, 223) 1px;
  border-radius: 5px;
  overflow: auto;
  height: 100%;
`;

const GraphQLSubTabContainer = styled.div`
  padding-left: 16px;
  overflow: auto;
  height: calc(100% - 95px);
`;

const adminOnlyFields = ["CreatedBy", "UpdatedBy"];

export default function ZuoraGraphQuery(props) {
  const [loading, setLoading] = React.useState(true);
  const [queryObject, setQueryObject] = React.useState("");
  const [baseObject, setBaseObject] = React.useState("");
  const [baseObjectType, setBaseObjectType] = React.useState("");
  const [baseObjectFilter, setBaseObjectFilter] = React.useState([]);
  const [baseObjectOrderByFields, setBaseObjectOrderByFields] = React.useState(
    [],
  );
  const [objectName, setObjectName] = React.useState("");
  const [fieldSelectionObjectPath, setFieldSelectionObjectPath] =
    React.useState("");
  const [baseObjectPath, setBaseObjectPath] = React.useState("");
  const [baseObjectFieldList, setBaseObjectFieldList] = React.useState([]);
  const [baseObjectBaseFieldList, setBaseObjectBaseFieldList] = React.useState(
    [],
  );
  const [baseObjectCustomFieldList, setBaseObjectCustomFieldList] =
    React.useState([]);
  const [fieldList, setFieldList] = React.useState([]);
  const [customFieldList, setCustomFieldList] = React.useState([]);
  const [baseFieldList, setBaseFieldList] = React.useState([]);
  const [containedCustomFields, setContainedCustomFields] =
    React.useState(false);
  const [linkedObjects, setLinkedObjects] = React.useState([]);
  const [querySelection, setQuerySelection] = React.useState(
    _.get(
      props,
      "currentActionConfig.actionConfiguration.customConfiguration.queryBuilder",
      {},
    ),
  );
  const [selectedSubTab, setSelectedSubTab] = React.useState("Fields");
  const [filterFields, setFilterFields] = React.useState([]);
  const [orderByFields, setOrderByFields] = React.useState([]);
  const [graphqlSchema, setGraphqlSchema] = React.useState({});
  const [queryObjects, setQueryObjects] = React.useState({});
  const [typeMap, setTypeMap] = React.useState({});
  const [baseObjectID, setBaseObjectID] = React.useState(
    _.get(
      props,
      "currentActionConfig.actionConfiguration.customConfiguration.graphqlParams.baseObjectID",
      "",
    ),
  );
  const [pageSize, setPageSize] = React.useState(
    _.get(
      props,
      "currentActionConfig.actionConfiguration.customConfiguration.graphqlParams.pageSize",
      2000,
    ),
  );
  const [cursor, setCursor] = React.useState(
    _.get(
      props,
      "currentActionConfig.actionConfiguration.customConfiguration.graphqlParams.cursor",
      `{{${props.actionName}.responseMeta.headers.nextPage ? ${props.actionName}.responseMeta.headers.endCursor : null }}`,
    ),
  );

  const [zuoraParser, setZuoraParser] = React.useState(
    _.get(
      props,
      "currentActionConfig.actionConfiguration.customConfiguration.zuoraParser",
      "",
    ),
  );

  async function fetchGraphSchema() {
    try {
      let schema;
      let introspecSchema = await ZGraphQLAPI.fetchGraphSchema({
        query: graphSchemaRequest,
      });

      if (introspecSchema.isAxiosError) {
        schema = buildClientSchema(introspectionSchemaResult);
      } else {
        schema = buildClientSchema(introspecSchema.data);
      }

      setGraphqlSchema(schema);
      setQueryObjects(schema._queryType._fields);
      setTypeMap(schema._typeMap);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    fetchGraphSchema();
  }, []);

  React.useEffect(() => {
    let newConfig = _.get(
      props,
      "currentActionConfig.actionConfiguration.customConfiguration",
      {},
    );

    setQuerySelection(_.get(newConfig, "queryBuilder", {}));
    setBaseObjectID(_.get(newConfig, "graphqlParams.baseObjectID", ""));
    setPageSize(_.get(newConfig, "graphqlParams.pageSize", 2000));
    setCursor(
      _.get(
        newConfig,
        "graphqlParams.cursor",
        `{{${props.actionName}.responseMeta.headers.nextPage ? ${props.actionName}.responseMeta.headers.endCursor : null }}`,
      ),
    );
    setZuoraParser(_.get(newConfig, "zuoraParser", ""));
    setSelectedSubTab("Fields");
  }, [props.actionName]);

  React.useLayoutEffect(() => {
    let temp = _.get(
      props,
      "currentActionConfig.actionConfiguration.customConfiguration.queryBuilder",
      {},
    );

    if (_.isEmpty(temp)) {
      setQuerySelection({});
      setQueryObject("");
      setBaseObject("");
      setBaseObjectType("");
      setObjectName("");
    }

    if (!_.isEmpty(temp) && !loading) {
      let name = _.keys(temp)[0];

      queryObjectOnSelect(
        queryObjects[name].type,
        name,
        true,
        queryObjects[name].args,
      );
    }
  }, [loading, props.actionName]);

  const queryObjectOnSelect = (objectType, name, edit, filterArgs) => {
    let subObject;

    if (objectType._interfaces[0].name === "singleObject") {
      subObject = objectType;
    } else {
      subObject = objectType._fields.edges.type.ofType._fields.node.type;
      setFilterFields(JSON.parse(filterFieldsFunc(filterArgs)));
      setOrderByFields(JSON.parse(orderByFieldsFunc(filterArgs)));
    }

    if (!edit) {
      let tempQuerySelection = {};

      tempQuerySelection[name] = {};
      tempQuerySelection[name].objectType = objectType._interfaces[0].name;
      tempQuerySelection[name].node = subObject.name;
      setQuerySelection(tempQuerySelection);
      setBaseObjectID("");
      setZuoraParser(
        objectType._interfaces[0].name === "singleObject"
          ? "ZuoraGraphqlNested"
          : "ZuoraGraphqlFlatten",
      );
    }

    setSelectedSubTab("Fields");

    let fieldsObject = subObject._fields;
    const { baseFieldList, containedCustomFields, customFieldList, fieldList } =
      getFieldLists(fieldsObject);

    let linkedObjects = getLinkedObjects(fieldsObject);

    setQueryObject(name);
    setBaseObject(subObject.name);
    setBaseObjectType(objectType._interfaces[0].name);
    setBaseObjectFilter(JSON.parse(filterFieldsFunc(filterArgs)));
    setBaseObjectOrderByFields(JSON.parse(orderByFieldsFunc(filterArgs)));
    setObjectName(subObject.name);
    setBaseObjectFieldList(fieldList);
    setBaseObjectBaseFieldList(baseFieldList);
    setFieldList(fieldList);
    setBaseObjectCustomFieldList(customFieldList);
    setCustomFieldList(customFieldList);
    setContainedCustomFields(containedCustomFields);
    setBaseFieldList(baseFieldList);
    setLinkedObjects(linkedObjects);
    setFieldSelectionObjectPath(name);
    setBaseObjectPath(name);
  };

  React.useEffect(() => {
    if (!loading) {
      onSave();
    }
  }, [querySelection, baseObjectID, pageSize, cursor, zuoraParser]);

  const onSave = () => {
    if (_.isEmpty(querySelection)) {
      return;
    }

    let payload = {};
    let temp = _.cloneDeep(querySelection);
    let finalQuerySelection = removeEmptyFilterConditions(temp);
    let graphqlParams = {
      baseObjectID: baseObjectID,
      pageSize: pageSize,
      cursor: `{{${props.actionName}.responseMeta.headers.nextPage ? ${props.actionName}.responseMeta.headers.endCursor : null }}`,
    };
    let result = queryBuilder(
      finalQuerySelection,
      baseObjectID,
      pageSize,
      cursor,
    );

    if (result.errors && result.errors.length > 0) {
      toast.show(
        "The following are missing required fields selection: " +
          result.errors.join(", "),
        {
          kind: "error",
        },
      );
    }

    payload["query"] = result.query;
    payload["variables"] = result.variables;
    props.change("actionConfiguration.body", payload);

    let config = _.cloneDeep(
      _.get(
        props,
        "currentActionConfig.actionConfiguration.customConfiguration",
        {},
      ),
    );

    config["queryBuilder"] = finalQuerySelection;
    config["graphqlParams"] = graphqlParams;
    config["zuoraParser"] = zuoraParser;

    props.change("actionConfiguration.customConfiguration", config);

    let headers = _.cloneDeep(
      _.get(props, "currentActionConfig.actionConfiguration.headers", []),
    );

    headers.push({ key: "Zuora-Query-Complexity", value: "10000" });
    headers.push({ key: "Zuora-Skip-Validation", value: true });
    headers.push({ key: "Zuora-Client-Id", value: "Appsmith" });
    headers.push({ key: "content-type", value: "application/json" });
    headers.push({ key: "Accept-Encoding", value: "gzip, deflate" });

    const uniqueHeaders = headers.filter(
      (header, index, self) =>
        index === self.findIndex((h) => h.key === header.key),
    );

    props.change("actionConfiguration.headers", uniqueHeaders);
  };

  const getSelectedField = () => {
    return _.get(querySelection, fieldSelectionObjectPath, {});
  };

  const hasMultipleObjects =
    _.get(querySelection, fieldSelectionObjectPath + ".objectType", "") ===
    "multipleObjects";

  /*
    Because some objects are nested within others, we need
    to check if the current object is the source object
    for the query. If it is not, we need to show the
    exclude condition controls.
   */
  const isCurrentObjectSourceObject =
    baseObjectPath === fieldSelectionObjectPath;

  /*
    Recursively check if the parent object has an
    inclusion condition.

    Because the include condition is only applied to the
    parent object, we need to check if the parent object
    has an inclusion condition to inform the user that
    any child objects will be affected.
   */
  const isParentConditionallyIncluded = (srcParentObjectPathStr) => {
    const parentObjectPath = srcParentObjectPathStr
      ? srcParentObjectPathStr.split(".")
      : fieldSelectionObjectPath.split(".");

    // we have reached the root object
    if (parentObjectPath.length === 1) {
      return false;
    }

    parentObjectPath.pop();

    const parentObjectPathStr = parentObjectPath.join(".");
    const parentObject = _.get(querySelection, parentObjectPathStr);

    return (
      parentObject?.includeCondition?.enabled ||
      isParentConditionallyIncluded(parentObjectPathStr)
    );
  };

  const updateSelectedObjectExclusion = (value, prevState, enabled) => {
    const newState = _.cloneDeep(prevState);
    const fieldSelectionObject = _.get(newState, fieldSelectionObjectPath);

    _.set(newState, fieldSelectionObjectPath, {
      ...fieldSelectionObject,
      includeCondition: {
        value,
        enabled: enabled ?? !!fieldSelectionObject?.includeCondition?.enabled,
      },
    });

    return newState;
  };

  return (
    <BodyWrapper>
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <Autocomplete
            autoHighlight
            disableClearable
            getOptionLabel={(option) => _.upperFirst(option)}
            onChange={(e, value) => {
              queryObjectOnSelect(
                queryObjects[value].type,
                value,
                false,
                queryObjects[value].args,
              );
            }}
            options={_.keys(queryObjects).sort()}
            renderInput={(params) => (
              <TextField {...params} label={"Base Object"} />
            )}
            sx={{
              ".MuiOutlinedInput-root .MuiAutocomplete-input": {
                padding: "0px 4px 7.5px 6px",
              },
              ".MuiOutlinedInput-root": {
                height: "36px !important",
              },
              ".MuiFormLabel-root": {
                marginBottom: "unset",
              },
            }}
            value={queryObject}
          />
        </div>

        {_.endsWith(queryObject, "ById") ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <Typography variant={"subtitle2"}>Object ID</Typography>
            <LazyCodeEditor
              {...props}
              border={CodeEditorBorder.ALL_SIDE}
              className="t--datasource-editor"
              focusElementName={"ID"}
              height="100%"
              hinting={[bindingHintHelper, slashCommandHintHelper]}
              input={{
                value: baseObjectID || "",
                onChange: (value) => {
                  setBaseObjectID(value);
                },
              }}
              theme={props.theme || EditorTheme.LIGHT}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              gap: "10px",
              flexGrow: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Typography variant={"subtitle2"}>Page Size</Typography>
              <LazyCodeEditor
                {...props}
                border={CodeEditorBorder.ALL_SIDE}
                className="t--datasource-editor"
                hinting={[bindingHintHelper, slashCommandHintHelper]}
                input={{
                  value: pageSize,
                  onChange: (value) => {
                    // let inputValue = Math.min(Math.max(0, value), 2000);
                    setPageSize(value);
                  },
                }}
                theme={props.theme || EditorTheme.LIGHT}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Typography variant={"subtitle2"}>Cursor</Typography>
              <DynamicTextField
                border={CodeEditorBorder.ALL_SIDE}
                dataTreePath={`${props.actionName}.config.customConfiguration.graphqlParams.cursor`}
                hoverInteraction
                name={`actionConfiguration.customConfiguration.graphqlParams.cursor`}
                size={EditorSize.COMPACT_RETAIN_FORMATTING}
                theme={props.theme}
              />
            </div>
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant={"subtitle2"}>Response Structure</Typography>
          <Select
            onSelect={(val) => {
              setZuoraParser(val);
            }}
            value={zuoraParser}
          >
            <Option
              key={`response-structure-nested`}
              value={"ZuoraGraphqlNested"}
            >
              Nested
            </Option>
            <Option
              key={`response-structure-flatten`}
              value={"ZuoraGraphqlFlatten"}
            >
              Flatten
            </Option>
          </Select>
        </div>
      </div>
      {baseObject ? (
        <GraphqueryQueryTabContainer>
          <ControlledTreeView
            baseObject={baseObject}
            baseObjectBaseFieldList={baseObjectBaseFieldList}
            baseObjectCustomFieldList={baseObjectCustomFieldList}
            baseObjectFieldList={baseObjectFieldList}
            baseObjectFilter={baseObjectFilter}
            baseObjectOrderByFields={baseObjectOrderByFields}
            baseObjectPath={baseObjectPath}
            baseObjectType={baseObjectType}
            filterFields={filterFields}
            linkObjects={linkedObjects}
            queryObject={queryObject}
            querySelection={querySelection}
            setBaseFieldList={(value) => setBaseFieldList(value)}
            setContainedCustomFields={(value) =>
              setContainedCustomFields(value)
            }
            setCustomFieldList={(value) => setCustomFieldList(value)}
            setFieldList={(value) => setFieldList(value)}
            setFieldSelectionObjectPath={(value) =>
              setFieldSelectionObjectPath(value)
            }
            setFilterFields={(value) => setFilterFields(value)}
            setObjectName={(value) => setObjectName(value)}
            setOrderByFields={(value) => setOrderByFields(value)}
            setQuerySelection={setQuerySelection}
            setSelectedSubTab={setSelectedSubTab}
            typeMap={typeMap}
          />
          {fieldList.length > 0 ? (
            <div
              style={{
                width: "75%",
                borderLeft: "solid rgb(205, 213, 223) 1px",
              }}
            >
              <div
                style={{
                  paddingLeft: "16px",
                  paddingTop: "16px",
                }}
              >
                <div style={{ paddingBottom: "16px" }}>
                  {_.upperFirst(objectName)}
                </div>
                <Tabs
                  onValueChange={(value) => setSelectedSubTab(value)}
                  value={selectedSubTab}
                >
                  <TabsList>
                    <Tab key="Fields" value={"Fields"}>
                      Standard Fields
                    </Tab>
                    {(containedCustomFields || customFieldList.length > 0) && (
                      <Tab key="Custom Fields" value={"CustomFields"}>
                        Custom Fields
                      </Tab>
                    )}
                    {hasMultipleObjects && (
                      <Tab key="Conditions" value={"Conditions"}>
                        Conditions
                      </Tab>
                    )}
                    {hasMultipleObjects && (
                      <Tab key="OrderBy" value={"OrderBy"}>
                        Order By
                      </Tab>
                    )}
                    {!isCurrentObjectSourceObject && (
                      <Tab key="options" value={"options"}>
                        Options
                      </Tab>
                    )}
                  </TabsList>
                </Tabs>
              </div>
              <GraphQLSubTabContainer>
                {selectedSubTab === "Fields" && (
                  <FieldsSelection
                    fieldList={baseFieldList}
                    isAdminLimit={adminOnlyFields.includes(
                      _.last(fieldSelectionObjectPath.split(".")),
                    )}
                    selectedRows={_.get(
                      querySelection,
                      fieldSelectionObjectPath + ".fields",
                      {},
                    )}
                    setQuerySelection={(value) => {
                      let temp = _.cloneDeep(querySelection);

                      setToValue(
                        temp,
                        value,
                        fieldSelectionObjectPath + ".fields",
                      );
                      setQuerySelection(temp);
                    }}
                  />
                )}
                {selectedSubTab === "CustomFields" && (
                  <FieldsSelection
                    allCustomFields={_.get(
                      querySelection,
                      fieldSelectionObjectPath + ".allCustomFields",
                      false,
                    )}
                    containedCustomFields={containedCustomFields}
                    fieldList={customFieldList}
                    selectedRows={_.get(
                      querySelection,
                      fieldSelectionObjectPath + ".customFields",
                      {},
                    )}
                    setCustomFieldOption={(value) => {
                      let temp = _.cloneDeep(querySelection);

                      setToValue(
                        temp,
                        value,
                        fieldSelectionObjectPath + ".allCustomFields",
                      );
                      setQuerySelection(temp);
                    }}
                    setQuerySelection={(value) => {
                      let temp = _.cloneDeep(querySelection);

                      setToValue(
                        temp,
                        value,
                        fieldSelectionObjectPath + ".customFields",
                      );
                      setQuerySelection(temp);
                    }}
                  />
                )}
                {selectedSubTab === "Conditions" && (
                  <ConditionTab
                    fieldSelectionObjectPath={fieldSelectionObjectPath}
                    objectFieldSelectProps={sortFunc(filterFields)}
                    querySelection={querySelection}
                    setQuerySelection={(value) => {
                      setQuerySelection(value);
                    }}
                  />
                )}
                {selectedSubTab === "options" && (
                  <Grid container sx={{ alignItems: "center", marginTop: 0 }}>
                    <Grid item>
                      <div>Conditional Include?</div>
                    </Grid>

                    <Grid item>
                      <input
                        checked={
                          !!getSelectedField()?.includeCondition?.enabled
                        }
                        onChange={({ target: { checked } }) => {
                          setQuerySelection((prev) =>
                            updateSelectedObjectExclusion(
                              getSelectedField()?.includeCondition?.value,
                              prev,
                              checked,
                            ),
                          );
                        }}
                        type="checkbox"
                      />
                    </Grid>

                    <Grid item sx={{ flexGrow: 1, paddingRight: "16px" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: 500 }} variant="label">
                          Include Condition&nbsp;
                        </Typography>
                        {isParentConditionallyIncluded() && (
                          <Typography color={"orangered"} variant={"caption"}>
                            (An inclusion condition has been set on a parent
                            object which may affect this selection.)
                          </Typography>
                        )}
                      </Box>

                      <LazyCodeEditor
                        {...props}
                        border={CodeEditorBorder.ALL_SIDE}
                        className="t--datasource-editor"
                        focusElementName={"ID"}
                        height="100%"
                        hinting={[bindingHintHelper, slashCommandHintHelper]}
                        input={{
                          value:
                            getSelectedField()?.includeCondition?.value || "",
                          onChange: (value) => {
                            setQuerySelection((prev) =>
                              updateSelectedObjectExclusion(value, prev),
                            );
                          },
                        }}
                        placeholder={
                          "Enter include condition (empty defaults to 'true')"
                        }
                        theme={props.theme || EditorTheme.LIGHT}
                      />
                    </Grid>
                  </Grid>
                )}
                {selectedSubTab === "OrderBy" && (
                  <Grid
                    container
                    sx={{ alignItems: "center", padding: "32px" }}
                  >
                    <Grid item xs={6}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: 1,
                        }}
                      >
                        <Typography variant={"subtitle3"}>Field</Typography>
                        <Autocomplete
                          freeSolo
                          id="free-solo-demo"
                          onInputChange={(e, value) => {
                            let temp = _.cloneDeep(querySelection);

                            if (
                              _.get(
                                temp,
                                fieldSelectionObjectPath + ".orderBy",
                                undefined,
                              ) === undefined
                            ) {
                              _.set(
                                temp,
                                fieldSelectionObjectPath + ".orderBy",
                                {},
                              );
                            }

                            setToValue(
                              temp,
                              value,
                              fieldSelectionObjectPath + ".orderBy.field",
                            );
                            setQuerySelection(temp);
                          }}
                          options={orderByFields.map((option) => option.value)}
                          renderInput={(params) => <TextField {...params} />}
                          sx={{
                            ".MuiOutlinedInput-root .MuiAutocomplete-input": {
                              padding: "4px",
                            },
                            ".MuiOutlinedInput-root": {
                              height: "40px !important",
                              marginTop: "-4px",
                            },
                            ".MuiFormLabel-root": {
                              marginBottom: "unset",
                            },
                          }}
                          value={getSelectedField()?.orderBy?.field || ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: 1,
                        }}
                      >
                        <Typography variant={"subtitle3"}>Order</Typography>
                        <Autocomplete
                          freeSolo
                          id="free-solo-demo"
                          onInputChange={(e, value) => {
                            let temp = _.cloneDeep(querySelection);

                            if (
                              _.get(
                                temp,
                                fieldSelectionObjectPath + ".orderBy",
                                undefined,
                              ) === undefined
                            ) {
                              _.set(
                                temp,
                                fieldSelectionObjectPath + ".orderBy",
                                {},
                              );
                            }

                            setToValue(
                              temp,
                              value,
                              fieldSelectionObjectPath + ".orderBy.order",
                            );
                            setQuerySelection(temp);
                          }}
                          options={["ASC", "DESC"]}
                          renderInput={(params) => <TextField {...params} />}
                          sx={{
                            ".MuiOutlinedInput-root .MuiAutocomplete-input": {
                              padding: "4px",
                            },
                            ".MuiOutlinedInput-root": {
                              height: "40px !important",
                              marginTop: "-4px",
                            },
                            ".MuiFormLabel-root": {
                              marginBottom: "unset",
                            },
                          }}
                          value={getSelectedField()?.orderBy?.order || "ASC"}
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}
              </GraphQLSubTabContainer>
            </div>
          ) : (
            <EmptyObject>Please choose an object to select fields</EmptyObject>
          )}
        </GraphqueryQueryTabContainer>
      ) : (
        <div
          style={{
            textAlign: "center",
            top: "50%",
            left: "50%",
            position: "sticky",
            flexDirection: "row",
          }}
        >
          Please select object and ID
        </div>
      )}
    </BodyWrapper>
  );
}
