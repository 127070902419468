export * from "ce/sagas/ZuoraSagas";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import {
  loadCustomEventComponentsSaga,
  loadCustomFieldComponentsSaga,
  loadObjectComponentsSaga,
  loadWorkflowComponentsSaga,
  saveCustomEventComponentsSaga,
  saveCustomFieldComponentsSaga,
  saveObjectComponentsSaga,
  saveWorkflowComponentsSaga,
} from "ce/sagas/ZuoraSagas";
import { all, takeLatest } from "redux-saga/effects";

export default function* zuoraSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.ZUORA_SAVE_OBJECT_COMPONENTS_INIT,
      saveObjectComponentsSaga,
    ),
    takeLatest(
      ReduxActionTypes.ZUORA_LOAD_OBJECT_COMPONENTS_INIT,
      loadObjectComponentsSaga,
    ),
    takeLatest(
      ReduxActionTypes.ZUORA_SAVE_CUSTOM_FIELDS_INIT,
      saveCustomFieldComponentsSaga,
    ),
    takeLatest(
      ReduxActionTypes.ZUORA_LOAD_CUSTOM_FIELDS_INIT,
      loadCustomFieldComponentsSaga,
    ),
    takeLatest(
      ReduxActionTypes.ZUORA_SAVE_CUSTOM_EVENTS_INIT,
      saveCustomEventComponentsSaga,
    ),
    takeLatest(
      ReduxActionTypes.ZUORA_LOAD_CUSTOM_EVENTS_INIT,
      loadCustomEventComponentsSaga,
    ),
    takeLatest(
      ReduxActionTypes.ZUORA_SAVE_WORKFLOWS_INIT,
      saveWorkflowComponentsSaga,
    ),
    takeLatest(
      ReduxActionTypes.ZUORA_LOAD_WORKFLOWS_INIT,
      loadWorkflowComponentsSaga,
    ),
  ]);
}
