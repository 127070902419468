/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import Container from "pages/UserAuth/Container";
import Helmet from "react-helmet";
import {
  SIGNUP_PAGE_SUBTITLE,
  SIGNUP_PAGE_TITLE,
  createMessage,
} from "ee/constants/messages";
import axios from "axios";
import PageLoadingBar from "pages/common/PageLoadingBar";
import PageNotFound from "pages/common/ErrorPages/PageNotFound";

function Extensions() {
  const [loading, setLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  const [notFound, setNotFound] = useState(false);
  useEffect(() => {
    const entityId = window.location.hostname.split(".")[0];
    let apiUrl;
    if (entityId.length === 32) apiUrl = `/api/v1/store/${entityId}`;
    else apiUrl = `/api/v1/store/zstore/${entityId}`;
    // const apiUrl = `/api/v1/workspaces/store/2c92c8f9638fc5750163aa7c513f0871`;
    axios
      .get(apiUrl)
      .then((response) => {
        // console.log("API response: ",response);
        // const newUrl = `http://${window.location.hostname}${response.data}`; // Adjust the protocol if needed
        // console.log("Redirecting to:", newUrl);
        // setRedirecting(true);
        // window.location.href = newUrl;
        let responseData = response.data;
        if (Array.isArray(responseData) && responseData.length > 0) {
          responseData = responseData[0].url; // Take the first element if it's an array
        }
        if (responseData.length === 0) {
          setNotFound(true);
        } else {
          const newUrl = `http://${window.location.hostname}${responseData}`; // Adjust the protocol if needed
          // console.log("Redirecting to:", newUrl);
          setRedirecting(true);
          window.location.href = newUrl;
        }
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        // console.log("Setting loading to false");
        setLoading(false);
      });
  });
  if (!loading && !redirecting) {
    // console.log("Rendering Container");
    if (notFound) {
      return <PageNotFound />;
    }
    return (
      <Container
        subtitle={createMessage(SIGNUP_PAGE_SUBTITLE)}
        title={createMessage(SIGNUP_PAGE_TITLE)}
      >
        <Helmet>
          <title>Extensions</title>
        </Helmet>
        <img
          alt="Zuora Logo"
          src="https://nav-assets.zuora.life/img/zuora-wordmark.svg"
        />
      </Container>
    );
  }
  return <PageLoadingBar />;
}

export default Extensions;
