import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentApplication,
  getIsChangingViewNav,
} from "ee/selectors/applicationSelectors";
import {
  APP_NAVIGATION_SETTING,
  createMessage,
  INCLUDE_IN_ZUORA_NAV,
  INCLUDE_IN_ZUORA_NAV_TOOLTIP,
  SHOW_ZUORA_NAV,
  SHOW_ZUORA_NAV_TOOLTIP,
} from "ee/constants/messages";
import type { NavigationSetting } from "constants/AppConstants";
import { NAVIGATION_SETTINGS } from "constants/AppConstants";
import _, { debounce, isEmpty, isPlainObject } from "lodash";
import ButtonGroupSetting from "./ButtonGroupSetting";
import ColorStyleIcon from "./ColorStyleIcon";
import SwitchSetting from "./SwitchSetting";
import type { UpdateApplicationPayload } from "ee/api/ApplicationApi";
import equal from "fast-deep-equal";
import {
  getCurrentApplicationId,
  getCurrentNavOption,
} from "selectors/editorSelectors";
import { updateApplication } from "ee/actions/applicationActions";
import { Divider, Select, Spinner, Switch, Option } from "@appsmith/ads";
import LogoInput from "pages/Editor/NavigationSettings/LogoInput";
import SwitchSettingForLogoConfiguration from "./SwitchSettingForLogoConfiguration";
import { selectFeatureFlags } from "ee/selectors/featureFlagsSelectors";
import { getIsFetchingApplications } from "ee/selectors/selectedWorkspaceSelectors";
import styled from "styled-components";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import {
  changeAppZuoraNav,
  changeAppZuoraNavOptions,
} from "ee/actions/zuoraActions";
import StyledPropertyHelpLabel from "./StyledPropertyHelpLabel";

export type UpdateSetting = (
  key: keyof NavigationSetting,
  value: NavigationSetting[keyof NavigationSetting],
) => void;

export interface LogoConfigurationSwitches {
  logo: boolean;
  applicationTitle: boolean;
}

function NavigationSettings() {
  const application = useSelector(getCurrentApplication);
  const applicationId = useSelector(getCurrentApplicationId);
  const featureFlags = useSelector(selectFeatureFlags);
  const dispatch = useDispatch();
  const [navigationSetting, setNavigationSetting] = useState(
    application?.applicationDetail?.navigationSetting,
  );
  const [logoConfigurationSwitches, setLogoConfigurationSwitches] =
    useState<LogoConfigurationSwitches>({
      logo: false,
      applicationTitle: false,
    });

  const navOptions = {
    Customers: "Customers",
    Products: "Products",
    Billing: "Billing",
    Payments: "Payments",
    Finance: "Finance",
    Reporting: "Reporting",
    Marketplace: "MyConnect",
    Platform: "Workflow",
    Commerce: "Commerce",
    Analytics: "Analytics",
    Extensions: "Extensions",
  };

  // zuora change start \\
  const [showPlacement, setShowPlacement] = useState(() => {
    // Check if application exists and navOption is an empty string
    if (
      application &&
      application.navOption !== undefined &&
      application.navOption === ""
    ) {
      return false; // Set showPlacement to false
    } else {
      return true; // Set showPlacement to true otherwise
    }
  });

  const Title = styled.p`
    font-size: var(--ads-v2-font-size-4);
    line-height: 1.2rem;
    font-weight: var(--ads-v2-font-weight-bold);
    color: var(--ads-v2-color-fg-emphasis);
  `;
  const isFetchingApplication = useSelector(getIsFetchingApplications);
  const isChangingViewNav = useSelector(getIsChangingViewNav);
  const navOption = useSelector(getCurrentNavOption);

  // zuora change end \\

  useEffect(() => {
    setNavigationSetting(application?.applicationDetail?.navigationSetting);

    // Logo configuration
    switch (navigationSetting?.logoConfiguration) {
      case NAVIGATION_SETTINGS.LOGO_CONFIGURATION.APPLICATION_TITLE_ONLY:
        setLogoConfigurationSwitches({
          logo: false,
          applicationTitle: true,
        });
        break;
      case NAVIGATION_SETTINGS.LOGO_CONFIGURATION.LOGO_AND_APPLICATION_TITLE:
        setLogoConfigurationSwitches({
          logo: true,
          applicationTitle: true,
        });
        break;
      case NAVIGATION_SETTINGS.LOGO_CONFIGURATION.LOGO_ONLY:
        setLogoConfigurationSwitches({
          logo: true,
          applicationTitle: false,
        });
        break;
      case NAVIGATION_SETTINGS.LOGO_CONFIGURATION.NO_LOGO_OR_APPLICATION_TITLE:
        setLogoConfigurationSwitches({
          logo: false,
          applicationTitle: false,
        });
        break;
      default:
        break;
    }
  }, [application?.applicationDetail?.navigationSetting]);

  useEffect(() => {
    if (
      logoConfigurationSwitches.logo &&
      logoConfigurationSwitches.applicationTitle
    ) {
      updateSetting(
        "logoConfiguration",
        NAVIGATION_SETTINGS.LOGO_CONFIGURATION.LOGO_AND_APPLICATION_TITLE,
      );
    } else if (
      logoConfigurationSwitches.logo &&
      !logoConfigurationSwitches.applicationTitle
    ) {
      updateSetting(
        "logoConfiguration",
        NAVIGATION_SETTINGS.LOGO_CONFIGURATION.LOGO_ONLY,
      );
    } else if (
      !logoConfigurationSwitches.logo &&
      logoConfigurationSwitches.applicationTitle
    ) {
      updateSetting(
        "logoConfiguration",
        NAVIGATION_SETTINGS.LOGO_CONFIGURATION.APPLICATION_TITLE_ONLY,
      );
    } else if (
      !logoConfigurationSwitches.logo &&
      !logoConfigurationSwitches.applicationTitle
    ) {
      updateSetting(
        "logoConfiguration",
        NAVIGATION_SETTINGS.LOGO_CONFIGURATION.NO_LOGO_OR_APPLICATION_TITLE,
      );
    }
  }, [logoConfigurationSwitches]);

  const updateSetting = useCallback(
    debounce(
      (
        key: keyof NavigationSetting,
        value: NavigationSetting[keyof NavigationSetting],
      ) => {
        if (
          navigationSetting &&
          isPlainObject(navigationSetting) &&
          !isEmpty(navigationSetting)
        ) {
          const newSettings: NavigationSetting = {
            ...navigationSetting,
            [key]: value,
          };

          if (!equal(navigationSetting, newSettings)) {
            const payload: UpdateApplicationPayload = {
              currentApp: true,
            };

            /**
             * If the orientation changes, we need to set a new default value
             * 1. in case of top, the default is stacked
             * 2. in case of side, the default is sidebar
             */
            if (navigationSetting.orientation !== newSettings.orientation) {
              newSettings.navStyle =
                newSettings.orientation === NAVIGATION_SETTINGS.ORIENTATION.TOP
                  ? NAVIGATION_SETTINGS.NAV_STYLE.STACKED
                  : NAVIGATION_SETTINGS.NAV_STYLE.SIDEBAR;
            }

            payload.applicationDetail = {
              navigationSetting: newSettings,
            };

            dispatch(updateApplication(applicationId, payload));
            setNavigationSetting(newSettings);
          }
        }
      },
      50,
    ),
    [navigationSetting],
  );

  if (!navigationSetting) {
    return (
      <div className="px-4 py-10 w-full flex justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div>
      {
        <>
          <div className="px-4 mt-4 mb-3">
            <Title>{createMessage(APP_NAVIGATION_SETTING.zNavHeader)}</Title>
          </div>
          <div className="px-4">
            <div className="flex justify-between content-center">
              <Switch
                data-testid="t--embed-settings-application-public"
                isDisabled={isFetchingApplication || isChangingViewNav}
                isSelected={application?.showZuoraNav}
                onChange={() => {
                  AnalyticsUtil.logEvent("MAKE_ZUO_NAV_SHOW", {
                    showZuoraNav: !application?.showZuoraNav,
                  });
                  application &&
                    dispatch(
                      changeAppZuoraNav(
                        application?.id,
                        !application.showZuoraNav,
                      ),
                    );
                }}
              >
                <StyledPropertyHelpLabel
                  label={createMessage(SHOW_ZUORA_NAV)}
                  lineHeight="1.17"
                  maxWidth="270px"
                  tooltip={createMessage(SHOW_ZUORA_NAV_TOOLTIP)}
                />
              </Switch>
            </div>
          </div>
          <div className="px-4 mt-4 mb-3">
            <div className="flex justify-between content-center">
              <Switch
                data-testid="t--embed-settings-application-public"
                isDisabled={isFetchingApplication || isChangingViewNav}
                isSelected={
                  application &&
                  application.navOption !== undefined &&
                  application.navOption !== ""
                }
                onChange={() => {
                  application &&
                    (showPlacement
                      ? dispatch(changeAppZuoraNavOptions(application?.id, ""))
                      : dispatch(
                          changeAppZuoraNavOptions(
                            application?.id,
                            "Extensions",
                          ),
                        ));
                  application && setShowPlacement(!showPlacement);
                }}
              >
                <StyledPropertyHelpLabel
                  label={createMessage(INCLUDE_IN_ZUORA_NAV)}
                  lineHeight="1.17"
                  maxWidth="270px"
                  tooltip={createMessage(INCLUDE_IN_ZUORA_NAV_TOOLTIP)}
                />
              </Switch>
            </div>
          </div>
          {showPlacement && (
            <>
              <div className="px-4 mt-4 mb-3">Placement:</div>
              <div className="px-4">
                <div className="flex justify-between content-center">
                  <section className="space-y-2">
                    <Select
                      dropdownClassName="t--theme-font-dropdown"
                      isDisabled={isFetchingApplication || isChangingViewNav}
                      onSelect={(value: string) => {
                        application &&
                          dispatch(
                            changeAppZuoraNavOptions(application?.id, value),
                          );
                      }}
                      value={navOption}
                    >
                      {Object.entries(navOptions).map(([key, value]) => (
                        <Option key={key} value={value}>
                          <div className="flex space-x-2 w-full cursor-pointer items-center">
                            <div className="leading-normal">{key}</div>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </section>
                </div>
              </div>
            </>
          )}
          <Divider />
        </>
      }
      {
        <>
          <div className="px-4 mt-4 mb-3">
            <Title>{createMessage(APP_NAVIGATION_SETTING.aNavHeader)}</Title>
          </div>
          <div className="px-4">
            <SwitchSetting
              keyName="showNavbar"
              label={createMessage(APP_NAVIGATION_SETTING.showNavbarLabel)}
              updateSetting={updateSetting}
              value={navigationSetting?.showNavbar}
            />

            {navigationSetting?.showNavbar && (
              <>
                <ButtonGroupSetting
                  heading={createMessage(
                    APP_NAVIGATION_SETTING.orientationLabel,
                  )}
                  keyName="orientation"
                  navigationSetting={navigationSetting}
                  options={[
                    {
                      label: _.startCase(NAVIGATION_SETTINGS.ORIENTATION.TOP),
                      value: NAVIGATION_SETTINGS.ORIENTATION.TOP,
                    },
                    {
                      label: _.startCase(NAVIGATION_SETTINGS.ORIENTATION.SIDE),
                      value: NAVIGATION_SETTINGS.ORIENTATION.SIDE,
                    },
                  ]}
                  updateSetting={updateSetting}
                />

                {navigationSetting?.orientation ===
                  NAVIGATION_SETTINGS.ORIENTATION.TOP && (
                  <ButtonGroupSetting
                    heading={createMessage(
                      APP_NAVIGATION_SETTING.navStyleLabel,
                    )}
                    keyName="navStyle"
                    navigationSetting={navigationSetting}
                    options={[
                      {
                        label: _.startCase(
                          NAVIGATION_SETTINGS.NAV_STYLE.STACKED,
                        ),
                        value: NAVIGATION_SETTINGS.NAV_STYLE.STACKED,
                        hidden:
                          navigationSetting?.orientation ===
                          NAVIGATION_SETTINGS.ORIENTATION.SIDE,
                      },
                      {
                        label: _.startCase(
                          NAVIGATION_SETTINGS.NAV_STYLE.INLINE,
                        ),
                        value: NAVIGATION_SETTINGS.NAV_STYLE.INLINE,
                        hidden:
                          navigationSetting?.orientation ===
                          NAVIGATION_SETTINGS.ORIENTATION.SIDE,
                      },
                      {
                        label: _.startCase(
                          NAVIGATION_SETTINGS.NAV_STYLE.SIDEBAR,
                        ),
                        value: NAVIGATION_SETTINGS.NAV_STYLE.SIDEBAR,
                        hidden:
                          navigationSetting?.orientation ===
                          NAVIGATION_SETTINGS.ORIENTATION.TOP,
                      },
                    ]}
                    updateSetting={updateSetting}
                  />
                )}

                <ButtonGroupSetting
                  heading={createMessage(
                    APP_NAVIGATION_SETTING.colorStyleLabel,
                  )}
                  keyName="colorStyle"
                  navigationSetting={navigationSetting}
                  options={[
                    {
                      value: NAVIGATION_SETTINGS.COLOR_STYLE.LIGHT,
                      label: (
                        <div className="flex items-center">
                          <ColorStyleIcon
                            colorStyle={NAVIGATION_SETTINGS.COLOR_STYLE.LIGHT}
                          />
                          <span>
                            {_.startCase(NAVIGATION_SETTINGS.COLOR_STYLE.LIGHT)}
                          </span>
                        </div>
                      ),
                    },
                    {
                      value: NAVIGATION_SETTINGS.COLOR_STYLE.THEME,
                      label: (
                        <div className="flex items-center">
                          <ColorStyleIcon
                            colorStyle={NAVIGATION_SETTINGS.COLOR_STYLE.THEME}
                          />
                          <span>
                            {_.startCase(NAVIGATION_SETTINGS.COLOR_STYLE.THEME)}
                          </span>
                        </div>
                      ),
                    },
                  ]}
                  updateSetting={updateSetting}
                />

                {(navigationSetting?.logoAssetId ||
                  featureFlags.release_appnavigationlogoupload_enabled) && (
                  <>
                    <SwitchSettingForLogoConfiguration
                      keyName="logo"
                      label={createMessage(
                        APP_NAVIGATION_SETTING.showLogoLabel,
                      )}
                      logoConfigurationSwitches={logoConfigurationSwitches}
                      setLogoConfigurationSwitches={
                        setLogoConfigurationSwitches
                      }
                    />

                    {(navigationSetting?.logoConfiguration ===
                      NAVIGATION_SETTINGS.LOGO_CONFIGURATION
                        .LOGO_AND_APPLICATION_TITLE ||
                      navigationSetting?.logoConfiguration ===
                        NAVIGATION_SETTINGS.LOGO_CONFIGURATION.LOGO_ONLY) && (
                      <LogoInput
                        navigationSetting={navigationSetting}
                        updateSetting={updateSetting}
                      />
                    )}
                  </>
                )}

                <SwitchSettingForLogoConfiguration
                  keyName="applicationTitle"
                  label={createMessage(
                    APP_NAVIGATION_SETTING.showApplicationTitleLabel,
                  )}
                  logoConfigurationSwitches={logoConfigurationSwitches}
                  setLogoConfigurationSwitches={setLogoConfigurationSwitches}
                />

                <SwitchSetting
                  keyName="showSignIn"
                  label={createMessage(APP_NAVIGATION_SETTING.showSignInLabel)}
                  tooltip={createMessage(
                    APP_NAVIGATION_SETTING.showSignInTooltip,
                  )}
                  updateSetting={updateSetting}
                  value={navigationSetting?.showSignIn}
                />
              </>
            )}
          </div>
          <Divider />
        </>
      }
    </div>
  );
}

export default NavigationSettings;
