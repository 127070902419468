/* eslint-disable @typescript-eslint/no-restricted-imports */
import Api from "api/Api";
import type { AxiosPromise } from "axios";
import type { ApiResponse } from "../ApiResponses";

export interface ResponseEnvelope<T> extends ApiResponse {
  data: T;
  status: number;
  message: string;
  timestamp: string;
  details: unknown[];
}

export abstract class ZuoraComponentsApi extends Api {
  static baseURL = "/v1/zuora/extension-component-type";

  static getSharedRequestHeaders = (extensionId: string) => ({
    "Extension-Id": extensionId,
  });

  static async checker<T>(
    extensionId: string | undefined,
    fnPtr: (...args: any[]) => Promise<AxiosPromise<T>>,
    ...args: any[]
  ): Promise<AxiosPromise<T>> {
    return extensionId
      ? fnPtr.call(null, ...args, extensionId)
      : ZuoraComponentsApi.handleNoExtensionIdFailure();
  }

  private static async handleNoExtensionIdFailure() {
    return Promise.reject(`Extension id not found`);
  }
}

export default ZuoraComponentsApi;
