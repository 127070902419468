import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { getCurrentUser } from "selectors/usersSelectors";
import { ANONYMOUS_USERNAME } from "constants/userConstants";
import { APPLICATIONS_URL, AUTH_LOGIN_URL } from "constants/routes";

export const requiresUnauth = (Component: React.ComponentType) => {
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function Wrapped(props: any) {
    const user = useSelector(getCurrentUser);
    const isLocal = process.env.REACT_APP_IS_LOCAL === "true";
    // Check if not local environment to retrieve zuoraEmail
    let zuoraEmail;
    if (!isLocal) {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.includes("ZNavIdentity")) {
          zuoraEmail = JSON.parse(localStorage[key])["username"];
          break; // Stop after finding the first valid zuoraEmail
        }
      }
    }
    if (!user) return null;
    if (
      user?.email &&
      user?.email !== ANONYMOUS_USERNAME &&
      (isLocal || user?.email === zuoraEmail)
    ) {
      return <Redirect to={APPLICATIONS_URL} />;
    }

    return <Component {...props} />;
  }

  return Wrapped;
};

export const requiresAuth = (Component: React.ComponentType) => {
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function Wrapped(props: any) {
    const user = useSelector(getCurrentUser);
    const isLocal = process.env.REACT_APP_IS_LOCAL === "true";
    // Check if not local environment to retrieve zuoraEmail
    let zuoraEmail;
    if (!isLocal) {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.includes("ZNavIdentity")) {
          zuoraEmail = JSON.parse(localStorage[key])["username"];
          break; // Stop after finding the first valid zuoraEmail
        }
      }
    }
    if (!user) return null;
    if (
      user?.email &&
      user?.email !== ANONYMOUS_USERNAME &&
      (isLocal || user?.email === zuoraEmail)
    ) {
      return <Component {...props} />;
    }

    return <Redirect to={AUTH_LOGIN_URL} />;
  };
};
